<template>
  <div>
    <DarkSand class="bottom-graphic" />
    <JellyFish class="jellyfish big" />
    <JellyFish class="jellyfish small" />
  </div>
</template>

<script>
import { gsap } from "gsap";
import DarkSand from "@/svgs/ctg1/dark-sand.svg";
import JellyFish from "@/svgs/ctg1/jellyfish.svg";

export default {
  components: {
    DarkSand,
    JellyFish
  },
  methods: {
    animateSand() {
      gsap.to(".bottom-graphic", gsap.utils.random(20, 25), {
        x: 0,
        repeat: -1,
        yoyo: true,
        ease: "linear"
      });
    },
    animateJellyFish() {
      let jellys = document.querySelectorAll(".jellyfish");
      jellys.forEach(jelly => {
        gsap.to(jelly, gsap.utils.random(5, 10), {
          yPercent: gsap.utils.random(50, 100),
          ease: "linear",
          yoyo: true,
          repeat: -1,
          scale: 1.5
        });
      });
    }
  },
  mounted() {
    this.animateJellyFish();
    this.animateSand();
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media-queries.scss";

.bottom-graphic {
  position: absolute;
  width: 200%;
  bottom: 0;
  transform: translateX(-10%);

  @include mobile {
    height: 100%;
  }
  @include tablet {
    bottom: -10%;
  }
  @include desktop {
    bottom: -30%;
    max-height: 1500px;
  }
  @include widescreen {
    max-height: 2000px;
  }
}

.jellyfish {
  position: absolute;
  &.big {
    height: 100px;
    left: 10%;
    top: 65%;
  }
  &.small {
    height: 50px;
    right: 10%;
    top: 10%;
  }
}
</style>
