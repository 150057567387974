<template>
  <div id="app" :class="getCurrentTheme">
    <Header />
    <Main />
    <CategoryGraphics />
    <div id="mask" :class="getNextTheme"></div>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Main from "./components/Main.vue";
import CategoryGraphics from "./components/CategoryGraphics.vue";

export default {
  name: "App",
  components: {
    Header,
    Main,
    CategoryGraphics,
  },
  computed: {
    getCurrentTheme() {
      if (this.showStart) {
        return "start";
      } else if (this.showEnd) {
        this.$store.dispatch("SET_CURRENT_THEME", "end");
        return "end";
      }
      return this.$store.getters.currentTheme;
    },
    getNextTheme() {
      return this.$store.getters.nextTheme;
    },
    showStart() {
      return this.$store.getters.showStart;
    },
    showEnd() {
      return this.$store.getters.showEnd;
    },
  },
};
</script>

<style lang="scss">
@import "styles/reset";
@import "styles/button";
@import "./styles/media-queries";

@font-face {
  font-family: "Telenor";
  src: url("./assets/font/Telenor.ttf");
  font-display: swap;
}

html {
  height: 100%;
}

body {
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: -webkit-fill-available;
}

#app {
  font-family: "Telenor", "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1a1a1a;
  line-height: 1.4;

  height: 100%;
  min-height: 100%;
  @include safari {
    height: -webkit-fill-available;
    min-height: -webkit-fill-available;
  }

  width: 100vw;
  max-width: 100vw;
  display: grid;
  grid-template-rows: 100px 1fr;
  grid-template-columns: minmax(20px, 1fr) repeat(6, minmax(auto, 200px)) minmax(
      20px,
      1fr
    );

  @include tablet {
    grid-template-columns: minmax(30px, 1fr) repeat(6, minmax(auto, 200px)) minmax(
        30px,
        1fr
      );
  }

  @include font-size-question();

  h1 {
    font-weight: 400;
  }

  .question-text {
    @include font-size-question();
  }

  .volume-svg {
    z-index: 10;
    overflow: initial;
    border-radius: 50%;
    position: fixed;
    height: 50px;
    width: 50px;
    padding: 12px 12px 10px 12px;
    top: 25px;
    right: 80px;

    &.pause {
      padding: 15px 10px;
    }

    &.start-screen {
      right: 25px;
      top: 90px;
    }

    @include tablet {
      right: 118px;
      top: 28px;
      height: 60px;
      width: 60px;
      padding: 12px 14px 10px 15px;
      &.pause {
        padding: 20px 15px;
      }
      &.start-screen {
        right: 60px;
        top: 130px;
      }
    }
    @include desktop {
      right: 121px;
      top: 33px;
      &.start-screen {
        right: 60px;
        top: 130px;
      }
    }

    &:hover {
      cursor: pointer;
    }

    &.dark {
      path {
        fill: #000;
      }
    }

    &.start {
      background: #00c8ff;
      box-shadow: 0px 4px 0px #000f3c;

      &:active,
      &:hover {
        background: darken(#00c8ff, 4%);
      }
      &:active {
        transform: translateY(4px) !important;
      }
    }

    &.water {
      background: #00c8ff;
      box-shadow: 0px 4px 0px #000f3c;

      &:hover {
        background: darken(#00c8ff, 4%);
      }
      &:active {
        transform: translateY(4px) !important;
      }

      &.dark {
        background: #b4ffff;
        box-shadow: 0px 4px 0px #00c8ff;

        &:hover {
          background: darken(#b4ffff, 4%);
        }
        &:active {
          transform: translateY(4px) !important;
        }
      }
    }
    &.space {
      background: #b4ffff;
      box-shadow: 0px 4px 0px #00c8ff;

      &:hover {
        background: darken(#b4ffff, 4%);
      }
      &:active {
        transform: translateY(4px) !important;
      }

      &.dark {
        background: #ffcad8;
        box-shadow: 0px 4px 0px #ff4173;

        &:active,
        &:hover {
          background-color: darken(#ffcad8, 4%);
        }
        &:active {
          transform: translateY(4px) !important;
        }
      }
    }
    &.city {
      background: #00c8ff;
      box-shadow: 0px 4px 0px #000f3c;

      &:hover {
        background: darken(#00c8ff, 4%);
      }

      &:active {
        transform: translateY(4px) !important;
      }
      &.dark {
        background: #b4ffff;
        box-shadow: 0px 4px 0px #00c8ff;

        &:active,
        &:hover {
          background-color: darken(#b4ffff, 4%);
        }
        &:active {
          transform: translateY(4px) !important;
        }
      }
    }
  }

  &.start {
    background: #e8fdff;
    button {
      $background: #00c8ff;
      background: $background;
      box-shadow: 0px 4px 0px #000f3c;

      &:hover {
        background-color: darken($background, 4%);
      }

      &:active {
        transform: translateY(4px) !important;
        box-shadow: 0px 2px 0px #e8fdff;
      }
      &.hidden-button {
        background: transparent;
        box-shadow: none;
        pointer-events: none;
        color: transparent;
      }
    }
  }

  &.water {
    $background: #e8fdff;

    $button-primary: #00c8ff;
    $button-primary-hover: darken($button-primary, 4%);
    $button-primary-shadow: #000f3c;

    $button-secondary: #00c8ff;
    $button-secondary-hover: darken($button-secondary, 4%);
    $button-secondary-shadow: #000f3c;

    background: $background;
    button {
      background: $button-primary;
      box-shadow: 0px 4px 0px $button-primary-shadow;

      &:active,
      &:hover {
        background-color: $button-primary-hover;
      }
      &:active {
        transform: translateY(4px) !important;
      }
    }

    .button-container {
      button {
        background: $button-secondary;
        box-shadow: 0px 4px 0px $button-secondary-shadow;

        &:active,
        &:hover {
          background-color: $button-secondary-hover;
        }
        &:active {
          transform: translateY(4px) !important;
        }
      }
    }
    &.dark {
      background: #000f3c;
      .question-text {
        color: white;
      }

      button {
        background: #b4ffff;
        box-shadow: 0px 4px 0px #00c8ff;

        &:active,
        &:hover {
          background-color: darken(#b4ffff, 4%);
        }
        &:active {
          transform: translateY(4px) !important;
        }
      }
    }
  }
  &.space {
    background: #ffcad8;

    button {
      $background: #b4ffff;
      background: $background;
      box-shadow: 0px 4px 0px #00c8ff;

      &:hover {
        background-color: darken($background, 4%);
      }

      &:active {
        transform: translateY(4px) !important;
        box-shadow: 0px 2px 0px #aea996;
      }
    }
    &.dark {
      background: #505050;
      .question-text {
        color: white;
      }
      button {
        background: #ffcad8;
        box-shadow: 0px 4px 0px #ff4173;

        &:active,
        &:hover {
          background-color: darken(#ffcad8, 4%);
        }
        &:active {
          transform: translateY(4px) !important;
        }
      }
    }
  }
  &.city {
    background: #fcf6f0;

    button {
      $background: #00c8ff;
      background: $background;
      box-shadow: 0px 4px 0px #000f3c;

      &:hover {
        background-color: darken($background, 4%);
      }

      &:active {
        transform: translateY(4px) !important;
      }
    }

    &.dark {
      background: #5f5f5f;
      .question-text {
        color: white;
      }
      button {
        background: #b4ffff;
        box-shadow: 0px 4px 0px #00c8ff;

        &:active,
        &:hover {
          background-color: darken(#b4ffff, 4%);
        }
        &:active {
          transform: translateY(4px) !important;
        }
      }
    }
  }
  &.end {
    background: #e8fdff;

    button {
      $background: #e8fdff;
      background: $background;
      box-shadow: 0px 4px 0px #000f3c;

      &:hover {
        background-color: darken($background, 4%);
      }

      &:active {
        transform: translateY(4px) !important;
      }
    }
  }

  header {
    grid-column: 1 / -1;
    grid-row: 1;
  }
  main {
    grid-column: 2 / -2;
    grid-row: 2;
    height: 100%;
    @include tablet {
      min-height: calc(100% - 100px);
    }
  }
}

.background-graphic-container {
  z-index: 0;
}
.start-container {
  position: relative;
  z-index: 1;
}
.start-button-container {
  margin: auto;
}
.main-container {
  position: relative;
  z-index: 1;
}
.question-container {
  position: relative;
  z-index: 1;
}
.answer-container {
  position: relative;
  z-index: 1;
}

#mask {
  position: absolute;
  height: 0px;

  &.water {
    background: #e8fdff;
    &.dark {
      background: #000f3c;
    }
  }
  &.space {
    background: #ffcad8;
    &.dark {
      background: #505050;
      &.first {
        background: #ffcad8;
      }
    }
  }
  &.city {
    background: #fcf6f0;
    &.dark {
      background: #5f5f5f;
    }
  }
}
</style>
