<template>
  <div>
    <UpperWave class="bottom-graphic upper" />
    <LowerWave class="bottom-graphic lower" />
    <Cloud class="cloud small" />
    <Cloud class="cloud big" />
  </div>
</template>

<script>
import { gsap } from "gsap";

import UpperWave from "@/svgs/ctg1/upper-wave.svg";
import LowerWave from "@/svgs/ctg1/lower-wave.svg";
import Cloud from "@/svgs/ctg1/cloud.svg";

export default {
  components: {
    UpperWave,
    LowerWave,
    Cloud
  },
  methods: {
    animateClouds() {
      let vw = window.innerWidth;
      let bigCloudtl = gsap.timeline({ repeat: -1 });
      bigCloudtl.set(".big", {
        x: -200,
        y: gsap.utils.random(150, 200)
      });
      bigCloudtl.to(".big", gsap.utils.random(30, 45), {
        x: vw + 200,
        ease: "linear"
      });

      let smallCloudtl = gsap.timeline({ repeat: -1 });
      smallCloudtl.set(".small", {
        width: "100px",
        x: -100,
        y: gsap.utils.random(0, 100)
      });
      smallCloudtl.to(".small", gsap.utils.random(30, 45), {
        x: vw + 100,
        ease: "linear"
      });
    },
    animateWaves() {
      gsap.to(".upper", gsap.utils.random(5, 10), {
        xPercent: -5,
        repeat: -1,
        yoyo: true,
        ease: "linear"
      });

      gsap.to(".lower", gsap.utils.random(5, 10), {
        xPercent: -5,
        repeat: -1,
        yoyo: true,
        ease: "linear"
      });
    },
    enter() {
      let tl = gsap.timeline({
        onComplete() {
          this.animateWaves();
          this.animateClouds();
        },
        callbackScope: this
      });
      tl.set(".bottom-graphic", {
        opacity: 0,
        pointerEvents: "none"
      });
      tl.fromTo(
        ".bottom-graphic",
        0.5,
        {
          y: "20%"
        },
        {
          y: "0%",
          opacity: 1,
          pointerEvents: "all"
        }
      );
    }
  },
  mounted() {
    this.enter();
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media-queries";

.cloud {
  position: absolute;

  &.big {
    width: 200px;
    transform: translateX(-200px);
    @include mobile {
      display: none;
    }
  }

  &.small {
    width: 100px;
    transform: translateX(-100px);
  }
}
.bottom-graphic {
  position: absolute;
  width: 200vw;
  max-height: 400px;

  &.upper {
    bottom: 10%;
    left: -50%;
    @include tablet {
      max-height: 600px;
      bottom: -10%;
    }
    @include desktop {
      bottom: -30%;
      max-height: 800px;
    }
    @include widescreen {
      max-height: 1100px;
    }
  }

  &.lower {
    bottom: 0;
    left: -10%;
    width: 300vw;

    @include tablet {
      max-height: 600px;
      bottom: -10%;
    }
    @include desktop {
      max-height: 1200px;
      bottom: -50%;
    }
    @include widescreen {
      max-height: 1400px;
      bottom: -50%;
    }
  }
}
</style>
