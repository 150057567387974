<template>
  <div class="background-graphics-container">
    <First v-if="getCurrentStep === 0" />
    <Second v-if="getCurrentStep === 1" />
    <Third v-else-if="getCurrentStep === 2" />
    <Fourth v-else-if="getCurrentStep === 3" />
  </div>
</template>

<script>
import Second from "./CtgOne/Second.vue";
import Fourth from "./CtgOne/Fourth.vue";
import Third from "./CtgOne/Third.vue";
import First from "./CtgOne/First.vue";

export default {
  components: {
    Second,
    Fourth,
    Third,
    First
  },
  computed: {
    getCurrentStep() {
      return this.$store.getters.questionStep;
    }
  }
};
</script>
