<template>
  <section class="question-container">
    <PauseVolume
      class="volume-svg pause"
      @click="playAudio"
      :class="checkTheme"
      v-if="this.playing"
    />

    <VolumeButton
      class="volume-svg"
      @click="playAudio"
      :class="checkTheme"
      v-else
    />

    <audio ref="audio" class="hidden-audio" @ended="audioEnded">
      <source :src="this.localAudio" type="audio/mp3" />
    </audio>

    <h1 class="question-text">
      {{ question.text }}
    </h1>
    <SpecficGraphic :step="question.id" />
    <div class="button-container">
      <button
        class="button one"
        name="one"
        @click="leave('one'), buttonClicked(question.id, 1)"
        v-if="question.buttonOne"
      >
        <span>
          {{ question.buttonOne }}
        </span>
      </button>
      <button
        class="button two"
        name="two"
        @click="leave('two'), buttonClicked(question.id, 2)"
        v-if="question.buttonTwo"
      >
        <span>
          {{ question.buttonTwo }}
        </span>
      </button>
      <button
        class="button three"
        name="three"
        @click="leave('three'), buttonClicked(question.id, 3)"
        v-if="question.buttonThree"
      >
        <span>
          {{ question.buttonThree }}
        </span>
      </button>
    </div>
  </section>
</template>

<script>
import SpecficGraphic from "./SpecficGraphic.vue";
import VolumeButton from "@/svgs/volume.svg";
import PauseVolume from "@/svgs/pause.svg";
import { updateUser } from "../axios";
import { gsap } from "gsap";

export default {
  data() {
    return {
      localAudio: this.question.audio_question,
      playing: false
    };
  },
  components: {
    SpecficGraphic,
    VolumeButton,
    PauseVolume
  },
  props: {
    question: {
      required: true,
      type: Object
    }
  },
  methods: {
    buttonClicked(question, button) {
      fetch("https://api.ipify.org?format=json")
        .then(response => response.json())
        .then(response => {
          this.clientIp = response.ip;
          let data = {
            ip: response.ip,
            answers: { question: question, button: button }
          };
          updateUser(data).then(data => {
            this.$emit("updateUser", data.user);
            this.ip = this.button = "";
          });
        });
    },
    audioEnded() {
      this.playing = false;
    },
    playAudio() {
      let audio = this.$refs.audio;
      audio.volume = 0.2;
      if (audio.paused) {
        this.playing = true;
        audio.play();
      } else {
        this.playing = false;
        audio.pause();
      }
    },
    leave(name) {
      this.playing = false;
      let audio = this.$refs.audio;
      audio.pause();
      let finButtonThree = document.querySelector(".three");
      let findFigure = document.querySelector(".figure");

      let tl = gsap.timeline({
        onComplete: function() {
          this.$emit("click", name, this.question.correctAnswer);
        },
        callbackScope: this
      });
      tl.fromTo(
        ".volume-svg",
        0.2,
        {
          yPercent: 0
        },
        {
          yPercent: 50,
          opacity: 0
        }
      );
      if (findFigure !== null) {
        tl.fromTo(
          ".figure",
          0.2,
          {
            yPercent: 0
          },
          {
            yPercent: 80,
            opacity: 0,
            ease: "linear"
          }
        );
      }

      tl.fromTo(
        ".question-text",
        0.2,
        {
          yPercent: 0
        },
        {
          yPercent: 80,
          opacity: 0,
          ease: "linear"
        },
        "-=0.3"
      );

      tl.fromTo(
        ".one, .two",
        0.5,
        {
          yPercent: 0
        },
        {
          yPercent: 50,
          opacity: 0
        }
      );
      // tl.fromTo(
      //   ".two",
      //   0.5,
      //   {
      //     yPercent: 0
      //   },
      //   {
      //     yPercent: 50,
      //     opacity: 0
      //   },
      //   "-=0.3"
      // );
      if (finButtonThree !== null) {
        tl.fromTo(
          ".three",
          0.5,
          {
            yPercent: 0
          },
          {
            yPercent: 50,
            opacity: 0
          },
          "-=0.5"
        );
      }
    },
    enter() {
      let findFigure = document.querySelector(".figure");
      let finButtonThree = document.querySelector(".three");

      if (findFigure !== null) {
        gsap.set(".figure", {
          opacity: 0
        });
      }

      gsap.set(".question-text, .button, .volume-svg", {
        opacity: 0,
        pointerEvents: "none"
      });

      let tl = gsap.timeline();
      tl.set("#mask", {
        height: "0vh"
      });
      tl.fromTo(
        ".volume-svg",
        0.5,
        {
          yPercent: 100
        },
        {
          yPercent: 0,
          opacity: 1,
          pointerEvents: "all"
        }
      );

      tl.fromTo(
        ".question-text",
        0.5,
        {
          y: "30%"
        },
        {
          y: "0%",
          opacity: 1
        }
      );

      tl.fromTo(
        ".figure",
        0.5,
        {
          y: "20%"
        },
        {
          y: "0%",
          opacity: 1
        }
      );

      tl.fromTo(
        ".one",
        0.5,
        {
          yPercent: 30
        },
        {
          yPercent: 0,
          opacity: 1,
          pointerEvents: "all"
        }
      );
      tl.fromTo(
        ".two",
        0.5,
        {
          yPercent: 30
        },
        {
          yPercent: 0,
          opacity: 1,
          pointerEvents: "all"
        },
        "-=0.3"
      );
      if (finButtonThree !== null) {
        tl.fromTo(
          ".three",
          0.5,
          {
            yPercent: 30
          },
          {
            yPercent: 0,
            opacity: 1,
            pointerEvents: "all"
          },
          "-=0.3"
        );
      }
    }
  },
  computed: {
    getTheme() {
      return this.$store.getters.currentTheme;
    },
    checkTheme() {
      let theme = this.getTheme;
      if (theme.includes("water")) {
        if (theme.includes("dark")) {
          return "water dark";
        }
        return "water light";
      } else if (theme.includes("space")) {
        if (theme.includes("dark")) {
          return "space dark";
        }
        return "space light";
      } else if (theme.includes("city")) {
        if (theme.includes("dark")) {
          return "city dark";
        }
        return "city light";
      }
      return "";
    }
  },
  mounted() {
    this.$store.dispatch("SET_CURRENT_THEME", this.question.theme);
    if (this.question.last) {
      this.$store.dispatch("SET_SHOW_LAST");
    }
    if (this.question.lastQuestion) {
      this.$store.dispatch("SET_SHOW_END");
    }
    this.enter();
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/media-queries";

.hidden-audio {
  position: absolute;
  left: -9999px;
}

.question-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @include tablet {
    @include height1024 {
      justify-content: initial;
    }
  }

  .question-text {
    font-size: 22px;
    @include mobile {
      align-self: baseline;
    }
    @include tablet {
      width: 80%;
      margin-left: 4vw;
      @include portrait {
        margin-top: 6vh;
      }
    }
    @include desktop {
      margin-top: 8vh;
      margin-left: 0;
      margin-left: -2vw;
    }
    @include desktop-large {
      width: 70%;
    }
  }

  .figure {
    align-self: center;
    overflow: initial;
    height: 300px;
    @include removeGraphic {
      display: none;
    }
    @include smallHeight {
      height: 20vh;
      width: auto;
    }
    /* @include smediumHeight {
      display: none;
    } */
    @include smallishHeight {
      height: 20vh;
    }

    @include mediumHeight {
      height: 25vh;
    }

    @include tablet {
      height: 35vh;
      @include height1024 {
        margin-top: 20px;
        height: 25vh;
      }
    }
    @include desktop {
      align-self: flex-end;
      margin-right: 15%;
      height: 40vh;
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    @include tablet {
      margin-bottom: 20px;
      @include height1024 {
        margin-bottom: 80px;
        margin-top: auto;
      }
    }

    @include desktop {
      margin-bottom: 60px;
    }

    button {
      margin: 7px 0;
      @include desktop {
        margin: 0 15px;
      }
    }
    @include desktop {
      flex-direction: row;
    }
  }

  @include desktop {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(11, 1fr) 60px;

    .question-text {
      grid-row: 1 / 6;
      grid-column: 2 / 10;
    }

    .figure {
      grid-row: 1 / -2;
      grid-column: 8 / -1;
      margin: 0;
      place-self: center;
    }

    .button-container {
      grid-row: 5 / -2;
      grid-column: 1 / -1;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-template-rows: 1fr;
      align-self: end;
      margin: 0;
    }
  }
}
</style>
