<template>
  <StartScreenAnimations v-if="showStart" />

  <!-- Water theme -->
  <CtgOneStartAnimations
    v-else-if="!showStart && showCtgStart && getNextTheme === 'water'"
  />
  <CategoryOneAnims
    v-else-if="
      (getCurrentTheme === 'water dark' || getCurrentTheme === 'water') &&
        !showStart &&
        !showCtgStart
    "
  />

  <!--space theme-->
  <CtgTwoStartAnimations
    v-else-if="
      !showStart &&
        showCtgStart &&
        (getNextTheme === 'space dark' || getNextTheme === 'space dark first')
    "
  />
  <CategoryTwoAnims
    v-else-if="
      (getCurrentTheme === 'space dark' || getCurrentTheme === 'space') &&
        !showStart &&
        !showCtgStart
    "
  />

  <!-- City theme -->
  <CtgThreeStartAnimations
    v-else-if="!showStart && showCtgStart && getNextTheme === 'city'"
  />
  <CategoryThreeAnims
    v-else-if="
      (getCurrentTheme === 'city' || getCurrentTheme === 'city dark') &&
        !showStart &&
        !showCtgStart
    "
  />

  <EndingScreenAnimations v-else />
</template>

<script>
import StartScreenAnimations from "./animations/StartScreenAnimations.vue";
import CtgOneStartAnimations from "./animations/CtgOneStartAnimations.vue";
import CtgTwoStartAnimations from "./animations/CtgTwoStartAnimations.vue";
import CtgThreeStartAnimations from "./animations/CtgThreeStartAnimations.vue";
import CategoryOneAnims from "./animations/CategoryOneAnims.vue";
import CategoryTwoAnims from "./animations/CategoryTwoAnims.vue";
import CategoryThreeAnims from "./animations/CategoryThreeAnims.vue";
import EndingScreenAnimations from "./animations/EndingScreenAnimations.vue";

export default {
  components: {
    StartScreenAnimations,
    CtgOneStartAnimations,
    CtgTwoStartAnimations,
    CtgThreeStartAnimations,
    CategoryOneAnims,
    CategoryTwoAnims,
    CategoryThreeAnims,
    EndingScreenAnimations
  },
  computed: {
    showStart() {
      return this.$store.getters.showStart;
    },
    showCtgStart() {
      return this.$store.getters.showCtgStart;
    },
    getCurrentTheme() {
      return this.$store.getters.currentTheme;
    },
    getNextTheme() {
      return this.$store.getters.nextTheme;
    },
    showEnd() {
      return this.$store.getters.showEnd;
    }
  }
};
</script>

<style lang="scss">
@import "../styles/media-queries";

.background-graphics-container {
  position: absolute;
  top: 0;
  height: calc(100% - 100px);
  margin-top: 100px;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: 1fr repeat(6, minmax(auto, 15em)) 1fr;
}
</style>
