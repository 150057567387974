<template>
  <div v-if="checkIfWin">
    <svg width="100%" height="100%" class="path-container">
      <path
        fill="none"
        id="ending-screen-path1"
        d="M5,133 C5,164 284,40 331,167 439.598,259.5 1.327,570.949 125.18,866.344 555.816,1156.896 924.097,576 952,576"
      />
    </svg>
    <svg width="100%" height="100%" class="path-container">
      <path
        fill="none"
        id="ending-screen-path2"
        d="M1132,744 C1132,775 1223.323,569.943 863.179,409.342 551.701,270.44 587.097,161 615,161"
      />
    </svg>
    <Ufo class="ufo ufo-win" />
    <MobilMerket class="mobil-merket-small mm-one" />
    <MobilMerket class="mobil-merket-small mm-two" />
  </div>
</template>

<script>
import Ufo from "@/svgs/ctg2/ufo.svg";
import MobilMerket from "@/svgs/mobil-merket-small.svg";
import { gsap } from "gsap";

export default {
  components: {
    Ufo,
    MobilMerket
  },
  data() {
    return {
      check: true
    };
  },
  methods: {
    moveRandom(element, check, percent) {
      if (check) {
        gsap.to(element, 5, {
          xPercent: gsap.utils.random(-percent, percent),
          yPercent: gsap.utils.random(-percent, percent),
          yoyo: true,
          ease: "linear",
          onComplete: this.moveRandom,
          onCompleteParams: [element, this.check, percent]
        });
      }
    },
    animate() {
      if (this.checkIfWin) {
        gsap.to(".mm-one", 40, {
          motionPath: {
            path: "#ending-screen-path1",
            align: "#ending-screen-path1",
            alignOrigin: [0.5, 0.5],
            autoRotate: 90
          },
          ease: "linear",
          repeat: -1
        });

        gsap.to(".mm-two", 40, {
          motionPath: {
            path: "#ending-screen-path2",
            align: "#ending-screen-path2",
            alignOrigin: [0.5, 0.5],
            autoRotate: 90
          },
          ease: "linear",
          repeat: -1
        });
      }

      let ufo = document.querySelector(".ufo");
      let redPlanet = document.querySelector(".red-planet");

      this.moveRandom(ufo, true, 10);
      this.moveRandom(redPlanet, true, 10);
      let billboard = document.querySelector(".billboard");
      if (billboard !== null) {
        this.moveRandom(billboard, true, 2.5);
      }
    }
  },
  computed: {
    getTotalCorrect() {
      return this.$store.getters.totalCorrect;
    },
    checkIfWin() {
      //Bug here with text not showing
      // if (this.getTotalCorrect >= 10) {
      //   return true;
      // }
      return false;
    }
  },
  mounted() {
    // this.animate();
  },
  beforeDestroy() {
    this.check = false;
  }
};
</script>

<style lang="scss" scoped>
.ufo-win {
  position: absolute;
  height: 40px;
  top: 10%;
  left: 5%;
}

.mobil-merket-small {
  height: 40px;
}
</style>
