<template>
  <div>
    <ConstellationOne class="constellation c-one" />
    <ConstellationTwo class="constellation c-two" />
    <ConstellationThree class="constellation c-three" />
    <ConstellationFour class="constellation c-four" />
  </div>
</template>

<script>
import { gsap } from "gsap";
import ConstellationOne from "@/svgs/ctg2/constellation-one.svg";
import ConstellationTwo from "@/svgs/ctg2/constellation-two.svg";
import ConstellationThree from "@/svgs/ctg2/constellation-three.svg";
import ConstellationFour from "@/svgs/ctg2/constellation-four.svg";

export default {
  components: {
    ConstellationOne,
    ConstellationTwo,
    ConstellationThree,
    ConstellationFour
  },
  data() {
    return {
      check: true
    };
  },
  methods: {
    moveRandom(element, check) {
      if (check) {
        gsap.to(element, 10, {
          xPercent: gsap.utils.random(-100, 100),
          yPercent: gsap.utils.random(-100, 100),
          yoyo: true,
          ease: "linear",
          onComplete: this.moveRandom,
          onCompleteParams: [element, this.check]
        });
      }
    },
    animate() {
      let allConstellations = document.querySelectorAll(".constellation");
      allConstellations.forEach(constellation => {
        this.moveRandom(constellation, true);
      });
      gsap.to(".constellation", 80, {
        rotation: 360,
        repeat: -1
      });
      gsap.to(".constellation", 20, {
        scale: 1.5,
        repeat: -1,
        yoyo: true
      });
    }
  },
  mounted() {
    this.animate();
  },
  beforeDestroy() {
    this.check = false;
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media-queries.scss";

.constellation {
  position: absolute;
  height: 50px;
  @include tablet {
    height: 100px;
  }
  &.c-one {
    top: 35%;
    left: 5%;
    @include mobile {
      top: 65%;
    }
    overflow: initial;
  }
  &.c-two {
    right: 33%;
    top: -10%;
  }
  &.c-three {
    right: 10%;
    top: 60%;
  }
  &.c-four {
    @include mobile {
      display: none;
    }
    top: 20%;
    right: 10%;
  }
}
</style>
