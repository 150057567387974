import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    questionStep: 0,
    showQuestion: false,
    currentTheme: "city",
    showStart: true,
    showEnd: false,
    showLast: false,
    totalCorrect: 0,
    showCtgStart: false,
    name: "",
    nextTheme: "water"
  },
  getters: {
    questionStep: state => state.questionStep,
    showQuestion: state => state.showQuestion,
    currentTheme: state => state.currentTheme,
    showStart: state => state.showStart,
    showEnd: state => state.showEnd,
    showLast: state => state.showLast,
    totalCorrect: state => state.totalCorrect,
    showCtgStart: state => state.showCtgStart,
    name: state => state.name,
    nextTheme: state => state.nextTheme,
    getState: state => state
  },
  mutations: {
    SET_QUESTION_STEP_INCREMENT(state) {
      state.questionStep++;
    },
    SET_QUESTION_STEP_DECREMENT(state) {
      state.questionStep--;
    },
    SET_SHOW_QUESTION(state) {
      state.showQuestion = !state.showQuestion;
    },
    SET_CURRENT_THEME(state, payload) {
      state.currentTheme = payload;
    },
    SET_SHOW_START(state) {
      state.showStart = !state.showStart;
    },
    SET_SHOW_END(state) {
      state.showEnd = !state.showEnd;
    },
    SET_SHOW_LAST(state) {
      state.showLast = !state.showLast;
    },
    SET_TOTAL_CORRECT(state) {
      state.totalCorrect++;
    },
    SET_SHOW_CTG_START(state) {
      state.showCtgStart = !state.showCtgStart;
    },
    SET_NAME(state, payload) {
      state.name = payload;
    },
    SET_NEXT_THEME(state, payload) {
      state.nextTheme = payload;
    },
    SET_STATE_FOR_RESET(state) {
      let resetState = {
        questionStep: 0,
        showQuestion: false,
        currentTheme: "water",
        showStart: false,
        showEnd: false,
        showLast: false,
        totalCorrect: 0,
        showCtgStart: true,
        name: state.name,
        nextTheme: "water"
      };
      Object.assign(state, resetState);
    }
  },
  actions: {
    SET_QUESTION_STEP_INCREMENT: context => {
      context.commit("SET_QUESTION_STEP_INCREMENT");
    },
    SET_QUESTION_STEP_DECREMENT: context => {
      context.commit("SET_QUESTION_STEP_DECREMENT");
    },
    SET_SHOW_QUESTION: context => {
      context.commit("SET_SHOW_QUESTION");
    },
    SET_CURRENT_THEME: (context, payload) => {
      context.commit("SET_CURRENT_THEME", payload);
    },
    SET_SHOW_START: context => {
      context.commit("SET_SHOW_START");
    },
    SET_SHOW_END: context => {
      context.commit("SET_SHOW_END");
    },
    SET_SHOW_LAST: context => {
      context.commit("SET_SHOW_LAST");
    },
    SET_TOTAL_CORRECT: context => {
      context.commit("SET_TOTAL_CORRECT");
    },
    SET_SHOW_CTG_START: context => {
      context.commit("SET_SHOW_CTG_START");
    },
    SET_NAME: (context, payload) => {
      context.commit("SET_NAME", payload);
    },
    SET_NEXT_THEME: (context, payload) => {
      context.commit("SET_NEXT_THEME", payload);
    },
    SET_STATE_FOR_RESET: context => {
      context.commit("SET_STATE_FOR_RESET");
    }
  }
});
