var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showStart)?_c('StartScreenAnimations'):(!_vm.showStart && _vm.showCtgStart && _vm.getNextTheme === 'water')?_c('CtgOneStartAnimations'):(
    (_vm.getCurrentTheme === 'water dark' || _vm.getCurrentTheme === 'water') &&
      !_vm.showStart &&
      !_vm.showCtgStart
  )?_c('CategoryOneAnims'):(
    !_vm.showStart &&
      _vm.showCtgStart &&
      (_vm.getNextTheme === 'space dark' || _vm.getNextTheme === 'space dark first')
  )?_c('CtgTwoStartAnimations'):(
    (_vm.getCurrentTheme === 'space dark' || _vm.getCurrentTheme === 'space') &&
      !_vm.showStart &&
      !_vm.showCtgStart
  )?_c('CategoryTwoAnims'):(!_vm.showStart && _vm.showCtgStart && _vm.getNextTheme === 'city')?_c('CtgThreeStartAnimations'):(
    (_vm.getCurrentTheme === 'city' || _vm.getCurrentTheme === 'city dark') &&
      !_vm.showStart &&
      !_vm.showCtgStart
  )?_c('CategoryThreeAnims'):_c('EndingScreenAnimations')}
var staticRenderFns = []

export { render, staticRenderFns }