<template>
  <section class="start-container">
    <BliKjent v-if="getNextTheme === 'water'" class="main-text" />

    <VennerOgDeling
      v-else-if="getNextTheme === 'space dark first'"
      class="main-text"
    />

    <TrygghetPaNett v-else class="main-text" />
    <button class="start-button single" @click="animateStart">
      <span>Jeg er klar!</span>
    </button>
  </section>
</template>

<script>
import { gsap } from "gsap";
import BliKjent from "../svgs/ctg1/bli-kjent.svg";
import VennerOgDeling from "../svgs/ctg2/venner-og-deling.svg";
import TrygghetPaNett from "../svgs/ctg3/trygghet-pa-nett.svg";

export default {
  components: {
    BliKjent,
    VennerOgDeling,
    TrygghetPaNett
  },
  data() {
    return {
      check: true
    };
  },
  computed: {
    getNextTheme() {
      return this.$store.getters.nextTheme;
    }
  },
  methods: {
    moveRandom(element, check) {
      if (check) {
        gsap.to(element, gsap.utils.random(5, 10), {
          xPercent: gsap.utils.random(-5, 5),
          yPercent: gsap.utils.random(-5, 5),
          ease: "linear",
          onComplete: this.moveRandom,
          onCompleteParams: [element, this.check]
        });
      }
    },
    animateStart() {
      let tl = gsap.timeline({
        onComplete: function() {
          this.$store.dispatch("SET_SHOW_QUESTION");
          this.$store.dispatch("SET_SHOW_CTG_START");
        },
        callbackScope: this
      });

      tl.set("#mask", {
        width: "100vw",
        height: "0vh",
        zIndex: 10
      });

      tl.to("#mask", 0.5, {
        height: "100vh"
      });
    },
    closeMask() {
      gsap.set("#mask", {
        height: "0vh"
      });
    },
    easeIn() {
      let tl = gsap.timeline();
      let mainText = document.querySelector(".main-text");
      this.moveRandom(mainText, true);

      tl.to(".main-text, .start-button", 1, {
        pointerEvents: "all",
        opacity: 1,
        y: 0
      });
    }
  },
  mounted() {
    gsap.set(".main-text, .start-button", {
      y: 20
    });
    this.closeMask();
    this.easeIn();
  },
  beforeDestroy() {
    this.check = false;
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media-queries.scss";

.start-container {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 27px;
  @include tablet {
    grid-template-rows: 1fr 1fr 1fr 1fr 60px;
  }

  .main-text {
    width: 80%;
    @include mobile {
      width: 85%;
    }
    max-width: 560px;
    @include desktop {
      max-width: 700px;
    }

    grid-row: 1 / 4;
    place-self: center;
    opacity: 0;
    pointer-events: none;

    .city & {
      max-width: 650px;
    }
  }

  button {
    grid-row: 4 / 5;
    place-self: center;
    align-self: end;
    min-width: 200px;
    @include tablet {
      @include height1024 {
        margin-bottom: 80px;
      }
    }
  }
}
</style>
