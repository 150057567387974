<template>
  <div>
    <First v-if="getCurrentStep === 9" />
    <Second v-if="getCurrentStep === 10" />
    <Third v-if="getCurrentStep === 11" />
    <Fourth v-if="getCurrentStep === 12" />
  </div>
</template>

<script>
import First from "./CtgThree/First.vue";
import Second from "./CtgThree/Second.vue";
import Third from "./CtgThree/Third.vue";
import Fourth from "./CtgThree/Fourth.vue";

export default {
  components: {
    First,
    Second,
    Third,
    Fourth
  },
  computed: {
    getCurrentStep() {
      return this.$store.getters.questionStep;
    }
  }
};
</script>

<style></style>
