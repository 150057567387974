<template>
  <div>
    <RedPlanet class="planet red" />
    <StarFace class="star face" />
    <PinkBottom class="bottom-graphic lower" />
    <Star class="star s-one" />
    <Star class="star s-two" />
    <Star class="star s-three" />
    <BluePlanet class="planet blue" />
  </div>
</template>

<script>
import { gsap } from "gsap";
import RedPlanet from "@/svgs/ctg2/red-planet.svg";
import BluePlanet from "@/svgs/ctg2/blue-planet.svg";
import PinkBottom from "@/svgs/ctg2/bottom-pink.svg";
import StarFace from "@/svgs/ctg2/star-face.svg";
import Star from "@/svgs/ctg2/star.svg";

export default {
  components: {
    RedPlanet,
    BluePlanet,
    PinkBottom,
    StarFace,
    Star
  },
  data() {
    return {
      check: true
    };
  },
  methods: {
    moveRandom(element, check) {
      if (check) {
        gsap.to(element, 10, {
          xPercent: gsap.utils.random(-50, 50),
          yPercent: gsap.utils.random(-50, 50),
          yoyo: true,
          ease: "linear",
          onComplete: this.moveRandom,
          onCompleteParams: [element, this.check]
        });
      }
    },
    animate() {
      let vw = window.innerWidth;
      gsap.set(".red", {
        x: vw
      });
      gsap.to(".red", 120, {
        x: -200
      });
      gsap.fromTo(
        ".red",
        5,
        {
          rotation: -10
        },
        {
          rotation: 10,
          repeat: -1,
          yoyo: true,
          ease: "linear"
        }
      );
      gsap.to(".blue, .star", 30, {
        rotation: 360,
        repeat: -1,
        ease: "linear"
      });
      gsap.to(".face", 10, {
        scale: 0.6,
        repeat: -1,
        ease: "linear",
        yoyo: true
      });
      let stars = document.querySelectorAll(".star");
      stars.forEach(star => {
        this.moveRandom(star, true);
      });
    }
  },
  mounted() {
    this.animate();
  },
  beforeDestroy() {
    this.check = false;
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media-queries";

.planet {
  position: absolute;
  height: 50px;
  &.red {
    @include mobile {
      display: none;
    }
  }
  &.blue {
    left: 30%;
    top: -10%;
    @include desktop {
      left: 70%;
      top: 10%;
    }
  }
}

.star {
  position: absolute;
  &.face {
    height: 70px;
    left: 5%;
    top: 40%;
    @include desktop {
      left: 40%;
      top: 30%;
    }
  }
  &.s-one {
    height: 20px;
    left: 10%;
    top: 20%;
  }
  &.s-two {
    @include mobile {
      display: none;
    }

    height: 25px;
    right: 5%;
    top: 40%;
  }
  &.s-three {
    @include mobile {
      display: none;
    }
    height: 45px;
    top: 50%;
    left: 14%;
  }
}

.bottom-graphic {
  position: absolute;
  width: 300%;

  &.lower {
    bottom: 0;
    max-height: 400px;

    @include tablet {
      max-height: 600px;
      bottom: -10%;
    }
    @include desktop {
      max-height: 800px;
      bottom: -30%;
    }
    @include widescreen {
      max-height: 1100px;
    }
  }
}
</style>
