<template>
  <div class="background-graphics-container">
    <First v-if="getCurrentStep === 4 || getCurrentStep === 5" />
    <!-- <Second v-else-if="getCurrentStep === 5" /> -->
    <Third v-else-if="getCurrentStep === 6" />
    <Fourth v-else-if="getCurrentStep === 7" />
    <Fifth v-else-if="getCurrentStep === 8" />
  </div>
</template>

<script>
import First from "./CtgTwo/First.vue";
import Third from "./CtgTwo/Third.vue";
import Fourth from "./CtgTwo/Fourth.vue";
import Fifth from "./CtgTwo/Fifth.vue";
// import Second from "./CtgTwo/Second.vue";

export default {
  components: {
    First,
    Third,
    Fourth,
    Fifth
  },
  computed: {
    getCurrentStep() {
      return this.$store.getters.questionStep;
    }
  }
};
</script>

<style lang="scss" scoped>
.constellation {
  position: absolute;
}
</style>
