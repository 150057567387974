<template>
  <div class="background-graphics-container">
    <LeftGrass class="left-grass" />
    <RightGrass class="right-grass" />
    <SmallWave class="small-wave-one" />
    <SmallWave class="small-wave-two" />
    <Fish class="fish fish-one" />
    <Fish class="fish fish-two" />
    <Fish class="fish fish-three" />
    <Shoe class="shoe" />
  </div>
</template>

<script>
import { gsap } from "gsap";
import LeftGrass from "../../svgs/ctg1/left-grass.svg";
import RightGrass from "../../svgs/ctg1/right-grass.svg";
import SmallWave from "../../svgs/ctg1/small-wave.svg";
import Fish from "../../svgs/ctg1/fish.svg";
import Shoe from "../../svgs/ctg1/shoe.svg";

export default {
  components: {
    LeftGrass,
    RightGrass,
    SmallWave,
    Fish,
    Shoe
  },
  data() {
    return {
      check: true
    };
  },
  methods: {
    moveRandom(element, check) {
      if (check) {
        gsap.to(element, gsap.utils.random(10, 15), {
          yPercent: gsap.utils.random(-200, 200),
          ease: "linear",
          onComplete: this.moveRandom,
          onCompleteParams: [element, this.check]
        });
      }
    },
    animateFigures() {
      let vw = window.innerWidth;
      let vh = window.innerHeight;

      gsap.fromTo(
        ".fish",
        2,
        {
          rotation: -10
        },
        {
          rotation: 10,
          repeat: -1,
          yoyo: true,
          ease: "linear"
        }
      );

      let fishes = document.querySelectorAll(".fish");
      fishes.forEach(fish => {
        this.moveRandom(fish, true);
        let fishtl = gsap.timeline({ repeat: -1 });
        fishtl.set(fish, {
          x: vw,
          y: gsap.utils.random(0, vh - 100)
        });
        fishtl.to(fish, gsap.utils.random(30, 50), {
          x: -40,
          ease: "linear"
        });
      });

      gsap.set(".small-wave-one", {
        x: 0 - 100,
        y: gsap.utils.random(100, vh / 2)
      });

      gsap.set(".small-wave-two", {
        x: 0 - 100,
        y: gsap.utils.random(vh / 2, vh - 200)
      });

      gsap.to(".small-wave-one", gsap.utils.random(25, 30), {
        x: vw,
        ease: "linear",
        repeat: -1
      });
      gsap.to(".small-wave-two", gsap.utils.random(35, 40), {
        x: vw,
        ease: "linear",
        repeat: -1
      });

      gsap.to(".shoe", 4, {
        y: "70px",
        repeat: -1,
        yoyo: true,
        ease: "linear"
      });
    }
  },
  mounted() {
    this.animateFigures();
  },
  beforeDestroy() {
    this.check = false;
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/media-queries";

.left-grass {
  position: absolute;
  bottom: 0;
  left: 0;
  @include mobile {
    display: none;
  }
  height: 20%;
}

.right-grass {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 20%;
}

.small-wave-one,
.small-wave-two {
  position: absolute;
  width: 100px;
}
.fish {
  position: absolute;
  width: 40px;
}

.shoe {
  position: absolute;
  left: 70%;
  top: -170px;
  width: 50px;
  @include mobile {
    top: -270px;
  }
}
</style>
