<template>
  <Figure1 v-if="step === 0" class="figure r-sm-d" />
  <Figure2 v-else-if="step === 1" class="figure" />
  <Figure3 v-else-if="step === 2" class="figure r-sm-d" />
  <Figure4 v-else-if="step === 3" class="figure r-sm-d" />

  <Figure5 v-else-if="step === 4" class="figure" />
  <Figure6 v-else-if="step === 5" class="figure" />
  <Figure7 v-else-if="step === 6" class="figure" />
  <Figure8 v-else-if="step === 7" class="figure r-sm-d" />
  <Figure9 v-else-if="step === 8" class="figure" />

  <Figure10 v-else-if="step === 9" class="figure r-sm-d" />
  <Figure11 v-else-if="step === 10" class="figure" />
  <Figure12 v-else-if="step === 11" class="figure" />
  <Figure13 v-else-if="step === 12" class="figure" />
</template>

<script>
import Figure1 from "@/svgs/ctg1/Figure1.vue";
import Figure2 from "@/svgs/ctg1/Figure2.vue";
import Figure3 from "@/svgs/ctg1/Figure3.vue";
import Figure4 from "@/svgs/ctg1/Figure4.vue";

import Figure5 from "@/svgs/ctg2/Figure1.vue";
import Figure6 from "@/svgs/ctg2/Figure2.vue";
import Figure7 from "@/svgs/ctg2/Figure3.vue";
import Figure8 from "@/svgs/ctg2/Figure4.vue";
import Figure9 from "@/svgs/ctg2/figure5.svg";

import Figure10 from "@/svgs/ctg3/Figure1.vue";
import Figure11 from "@/svgs/ctg3/Figure2.vue";
import Figure12 from "@/svgs/ctg3/Figure3.vue";
import Figure13 from "@/svgs/ctg3/Figure4.vue";

import { gsap } from "gsap";

export default {
  props: {
    step: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      check: true
    };
  },
  components: {
    Figure1,
    Figure2,
    Figure3,
    Figure4,
    Figure5,
    Figure6,
    Figure7,
    Figure8,
    Figure9,
    Figure10,
    Figure11,
    Figure12,
    Figure13
  },
  methods: {
    moveRandom(element, check) {
      if (check) {
        gsap.to(element, 5, {
          xPercent: gsap.utils.random(-5, 5),
          yPercent: gsap.utils.random(-5, 5),
          yoyo: true,
          ease: "linear",
          onComplete: this.moveRandom,
          onCompleteParams: [element, this.check]
        });
      }
    }
  },
  mounted() {
    let figure = document.querySelector(".figure");
    this.moveRandom(figure, true);
  },
  beforeDestroy() {
    this.check = false;
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media-queries.scss";
</style>
