import axios from "axios";
const BASE_URL = "";

var answersArray = [];

export function createUser(data) {
  return axios
    .post(`${BASE_URL}/api/user/create`, { ip: data.ip })
    .then(response => {
      return response.data;
    });
}

export function updateUser(data) {
  var joined = answersArray.concat(data.answers);
  answersArray = joined;

  return axios
    .post(`${BASE_URL}/api/user/update`, { ip: data.ip, answers: joined })
    .then(response => {
      return response.data;
    });
}

