import Q1 from "@/assets/audio/SPM1.mp3";
import Q1A1 from "@/assets/audio/SPM1_A1.mp3";
import Q1A2 from "@/assets/audio/SPM1_A2.mp3";
import Q1A3 from "@/assets/audio/SPM1_A3.mp3";

import Q2 from "@/assets/audio/SPM2.mp3";
import Q2A1 from "@/assets/audio/SPM2_A1.mp3";
import Q2A2 from "@/assets/audio/SPM2_A2.mp3";
import Q2A3 from "@/assets/audio/SPM2_A3.mp3";

import Q3 from "@/assets/audio/SPM3.mp3";
import Q3A1 from "@/assets/audio/SPM3_A1.mp3";
import Q3A2 from "@/assets/audio/SPM3_A2.mp3";
import Q3A3 from "@/assets/audio/SPM3_A3.mp3";

import Q4 from "@/assets/audio/SPM4.mp3";
import Q4A1 from "@/assets/audio/SPM4_A1.mp3";
import Q4A2 from "@/assets/audio/SPM4_A2.mp3";

import Q5 from "@/assets/audio/SPM5.mp3";
import Q5A1 from "@/assets/audio/SPM5_A1.mp3";
import Q5A2 from "@/assets/audio/SPM5_A2.mp3";

import Q6 from "@/assets/audio/SPM6.mp3";
import Q6A1 from "@/assets/audio/SPM6_A1.mp3";
import Q6A2 from "@/assets/audio/SPM6_A2.mp3";

import Q7 from "@/assets/audio/SPM7.mp3";
import Q7A1 from "@/assets/audio/SPM7_A1.mp3";
import Q7A2 from "@/assets/audio/SPM7_A2.mp3";

import Q8 from "@/assets/audio/SPM8.mp3";
import Q8A1 from "@/assets/audio/SPM8_A1.mp3";
import Q8A2 from "@/assets/audio/SPM8_A2.mp3";
import Q8A3 from "@/assets/audio/SPM8_A3.mp3";

import Q9 from "@/assets/audio/SPM9.mp3";
import Q9A1 from "@/assets/audio/SPM9_A1.mp3";
import Q9A2 from "@/assets/audio/SPM9_A2.mp3";
import Q9A3 from "@/assets/audio/SPM9_A3.mp3";

import Q10 from "@/assets/audio/SPM10.mp3";
import Q10A1 from "@/assets/audio/SPM10_A1.mp3";
import Q10A2 from "@/assets/audio/SPM10_A2.mp3";
import Q10A3 from "@/assets/audio/SPM10_A3.mp3";

import Q11 from "@/assets/audio/SPM11.mp3";
import Q11A1 from "@/assets/audio/SPM11_A1.mp3";
import Q11A2 from "@/assets/audio/SPM11_A2.mp3";
import Q11A3 from "@/assets/audio/SPM11_A3.mp3";

import Q12 from "@/assets/audio/SPM12.mp3";
import Q12A1 from "@/assets/audio/SPM12_A1.mp3";
import Q12A2 from "@/assets/audio/SPM12_A2.mp3";

import Q13 from "@/assets/audio/SPM13.mp3";
import Q13A1 from "@/assets/audio/SPM13_A1.mp3";
import Q13A2 from "@/assets/audio/SPM13_A2.mp3";
import Q13A3 from "@/assets/audio/SPM13_A3.mp3";

const questions = [
  {
    id: 0,
    text:
      "Koden bruker du til å låse opp mobilen. Er det lurt å gi den til andre enn foreldrene dine?",
    buttonOne: "Nei, man skal ikke gi koden til andre!",
    buttonTwo: "Bare hvis det er en bestevenn.",
    buttonThree: "Ja, så lenge det er til en jeg kjenner.",
    correctAnswer: ["one"],
    answerOne: "Kjempebra, [NAVN], helt riktig!",
    answerTwo: "Nei, selv ikke bestevenn bør få koden.",
    answerThree: "Niks, koden er bare din.",
    theme: "water dark",
    audio_question: Q1,
    audio_response1: Q1A1,
    audio_response2: Q1A2,
    audio_response3: Q1A3
  },
  {
    id: 1,
    text: "Å nei! Du har mistet mobilen! Hva gjør du?",
    buttonOne: "Jeg sier ikke noe, for da blir de voksne sure.",
    buttonTwo: "Jeg sier ifra til en voksen som kan få den sperret.",
    buttonThree: "Det går bra! Jeg kan få en ny.",
    correctAnswer: ["two"],
    answerOne:
      "Det kan godt hende de blir litt skuffet, men telefonen må sperres, så de voksne trenger å få beskjed. Dette fikser du, [NAVN]!",
    answerTwo:
      "Flott, [NAVN]! Det er viktig å få sperret mobilen din så fort som mulig så ingen andre kan bruke den.",
    answerThree:
      "Hmm. Du kan ønske deg ny mobil, men det er viktig å få sperret den du har mistet så ingen andre kan bruke den.",
    theme: "water",
    audio_question: Q2,
    audio_response1: Q2A1,
    audio_response2: Q2A2,
    audio_response3: Q2A3
  },
  {
    id: 2,
    text: "Kan du laste ned apper som er gratis, og bruke de som du vil?",
    buttonOne: "Ja, det er min mobiltelefon, så jeg bestemmer.",
    buttonTwo: "Alt som  er gratis kan jeg bruke.",
    buttonThree: "Nei, jeg må spørre foreldrene mine først.",
    correctAnswer: ["three"],
    answerOne:
      "Ja, det er din mobiltelefon, men det er mange apper som har en aldersgrense. Foreldrene dine har ansvaret for at du ikke bruker apper som ikke er for barn, og derfor må du spørre dem.",
    answerTwo:
      "Det stemmer ikke! Selv om det er gratis, er det mange apper som har en aldersgrense. Derfor er det viktig at du spør foreldrene dine.",
    answerThree:
      "Veldig bra! Det finnes flere apper som barn ikke har lov til å bruke. Flott at du spør foreldrene dine.",
    theme: "water dark",
    audio_question: Q3,
    audio_response1: Q3A1,
    audio_response2: Q3A2,
    audio_response3: Q3A3
  },
  {
    id: 3,
    text: "Tåler mobilen alt, eller kan den bli ødelagt?",
    buttonOne:
      "En mobil tåler ikke alt. Den koster mye og jeg må være forsiktig.",
    buttonTwo: "Mobilen tåler alt. Den kan til og med falle på bakken.",
    correctAnswer: ["one"],
    answerOne: "Det stemmer, [NAVN] - det er viktig å passe godt på mobilen!",
    answerTwo:
      "Mobilen tåler mye, men ikke alt og det koster å reparere den. Pass godt på den, så har du den lenge.",
    theme: "water",
    lastOfCategory: true,
    nextTheme: "space",
    audio_question: Q4,
    audio_response1: Q4A1,
    audio_response2: Q4A2
  },
  {
    id: 4,
    text: "Du tar et bilde av en venn. Er det ok å sende til andre?",
    buttonOne: "Ja, det var jeg som tok bildet.",
    buttonTwo:
      "Nei, ikke del bilder av andre uten å spørre vennen og en voksen.",
    correctAnswer: ["two"],
    answerOne: "Hmm.. Selv om du har tatt bildet, så er det ikke greit å dele.",
    answerTwo:
      "Veldig bra, nå er du god, [NAVN]! Husk at barn også må spørre en voksen.",
    theme: "space dark",
    audio_question: Q5,
    audio_response1: Q5A1,
    audio_response2: Q5A2
  },
  {
    id: 5,
    text: "Er det lurt å lagre nummeret til familien og venner på mobilen?",
    buttonOne: "Nei, jeg hører hvem det er når jeg svarer.",
    buttonTwo: "Ja, da kan jeg være trygg på hvem som ringer meg.",
    correctAnswer: ["two"],
    answerOne:
      "Om du ikke lagrer telefonnummeret til de du kjenner vet du heller ikke hvem som ringer, noen ganger kan det være noen som prøver å lure deg.",
    answerTwo:
      "Lurt! Få hjelp av en voksen til å lagre de kontaktene du trenger, så vet du om det er familie eller venner som ringer deg, og ikke noen som prøver å lure deg.",
    theme: "space",
    audio_question: Q6,
    audio_response1: Q6A1,
    audio_response2: Q6A2
  },
  {
    id: 6,
    text:
      "Noen du ikke vet hvem er, prøver å ta kontakt med deg på mobilen. Hva gjør du?",
    buttonOne: "Så gøy at noen tar kontakt! Jeg svarer tilbake.",
    buttonTwo:
      "Jeg sier ifra til en voksen fordi jeg ikke vet hvem det er som tar kontakt.",
    correctAnswer: ["two"],
    answerOne:
      "Ikke gjør det. Du kan ikke vite om den som tar kontakt prøver å lure deg.",
    answerTwo:
      "Veldig bra. Du vet ikke om den som tar kontakt prøver å lure deg. En voksen kan hjelpe deg med dette.",
    theme: "space dark",
    audio_question: Q7,
    audio_response1: Q7A1,
    audio_response2: Q7A2
  },
  {
    id: 7,
    text:
      "Noen sender en melding til deg som gjør at du føler deg mobbet. Hva gjør du?",
    buttonOne: "Jeg svarer tilbake. «Jeg bryr meg ikke og du er en dust».",
    buttonTwo: "Jeg bare sletter det og later som ingenting.",
    buttonThree: "Jeg lagrer meldingen og viser til en voksen.",
    correctAnswer: ["three"],
    answerOne: "Ikke gjør det. Det er like dumt å skrive stygge ting tilbake.",
    answerTwo:
      "Du bør si ifra til en voksen, og den som sendte meldingen bør få beskjed om at det ikke er greit å gjøre sånt.",
    answerThree:
      "Veldig bra, [NAVN]! Dette må vi få slutt på. Her må de voksne snakke sammen og sørge for at det ikke skjer igjen.",
    theme: "space",
    audio_question: Q8,
    audio_response1: Q8A1,
    audio_response2: Q8A2,
    audio_response3: Q8A3
  },
  {
    id: 8,
    text:
      "Det gjør vondt å bli mobbet. Hva skal du gjøre om det skjer med en du kjenner?",
    buttonOne: "Ikke noe. Jeg vil ikke sladre.",
    buttonTwo: "Jeg mobber tilbake.",
    buttonThree: "Nei til mobbing! Jeg sier ifra til en voksen.",
    correctAnswer: ["three"],
    answerOne:
      "Selv om det ikke gjelder deg, [NAVN], så er det galt å plage andre. Si ifra til en voksen som kan hjelpe den som blir mobbet. Det er ikke sladring, du er en god venn.",
    answerTwo:
      "Å ta igjen kan kanskje føles godt en kort stund. Men det løser ikke problemet. Det kan faktisk bli verre. Gi beskjed til en voksen, som kan stoppe mobbingen.",
    answerThree:
      "Bra, [NAVN]! Det er ikke sladring å hjelpe en som har det vondt. Det er modig. Husk også at voksne kan hjelpe deg å få slettet det som ligger på nett hvis det er ille.",
    theme: "space dark",
    lastOfCategory: true,
    nextTheme: "city",
    audio_question: Q9,
    audio_response1: Q9A1,
    audio_response2: Q9A2,
    audio_response3: Q9A3
  },
  {
    id: 9,
    text:
      "Du får en melding som sier: Send denne videre til alle så får du en premie. Hva gjør du?",
    buttonOne: "Nei, det er bare tull! Jeg bare sletter den.",
    buttonTwo: "Kult! Jeg sender videre, så får jeg premie.",
    buttonThree: "Jeg er litt usikker, så viser til en voksen.",
    correctAnswer: ["one", "three"],
    answerOne:
      "[NAVN], dette her fiksa du bra! Si gjerne fra til en voksen, som kan sørge for at de som sender sånne meldinger slutter med det.",
    answerTwo:
      "Du bør ikke sende videre! Det kan være noen som prøver å lure deg. Vis meldingen til en voksen, som kan hjelpe deg.",
    answerThree:
      "Så bra! Ikke send meldingen videre. Flott at du spør en voksen.",
    theme: "city",
    audio_question: Q10,
    audio_response1: Q10A1,
    audio_response2: Q10A2,
    audio_response3: Q10A3
  },
  {
    id: 10,
    text: "Du er på kafé, og der er det gratis internett. Bør du bruke det?",
    buttonOne: "Ja, gratis er bra.",
    buttonTwo: "Ja, så lenge det er passord og jeg har spurt en voksen.",
    buttonThree: "Ja, da sparer jeg jo masse data!",
    correctAnswer: ["two"],
    answerOne:
      "Ikke så lurt. Du bør aldri logge på et gratis nett uten passord - og du må alltid spørre en voksen først.",
    answerTwo:
      "Helt riktig! Du bør alltid sjekke at nettet har et passord, og spørre en voksen først.",
    answerThree:
      "Det er bra å spare data, men du bør aldri logge på et gratis nett uten passord - og du må alltid spørre en voksen først.",
    theme: "city dark",
    audio_question: Q11,
    audio_response1: Q11A1,
    audio_response2: Q11A2,
    audio_response3: Q11A3
  },
  {
    id: 11,
    text:
      "Du laster ned et spill som er helt gratis. Men er det egentlig alltid gratis å spille?",
    buttonOne:
      "Nei, noen spill er gratis å laste ned, men det kan koste penger å kjøpe ting i spillet etter hvert.",
    buttonTwo: "Ja, det er jo gratis.",
    correctAnswer: ["one"],
    answerOne:
      "Mange spill er gratis i starten, men du kan ofte kjøpe ekstra ting eller utstyr etter hvert. Det er lett å bli lurt, så du bør spørre en voksen om råd.",
    answerTwo:
      "Mange spill er gratis i starten, men du kan ofte kjøpe ekstra ting eller utstyr etter hvert. Det er lett å bli lurt, så du bør spørre en voksen om råd.",
    theme: "city",
    audio_question: Q12,
    audio_response1: Q12A1,
    audio_response2: Q12A2
  },
  {
    id: 12,
    text:
      "Å ta bilde av seg selv med mobilen er både enkelt og gøy. Sender du sånne bilder til andre?",
    buttonOne: "Ja, jeg deler bilder med alle!",
    buttonTwo: "Ja, men bare hvis bildet er fint.",
    buttonThree: "Bare til mine nærmeste.",
    correctAnswer: ["three"],
    answerOne: "Du burde aldri dele bilder med noen du ikke kjenner.",
    answerTwo:
      "Selv om bildet er fint, bør du kun sende det til dine nærmeste.",
    answerThree:
      "Helt riktig! Du kan også sende til gode venner hvis foreldrene dine sier det er greit.",
    theme: "city dark",
    last: true,
    audio_question: Q13,
    audio_response1: Q13A1,
    audio_response2: Q13A2,
    audio_response3: Q13A3
  },
  {
    id: 13,
    lastQuestion: true,
    theme: "city"
  }
];

export default questions;
