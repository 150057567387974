<template>
  <div class="background-graphics-container">
    <CityBackdrop class="bottom-graphic" />
    <BirdOnScooter class="bird-on-scooter" />
    <SmallRainbow class="small-rainbow rb-one" />
    <SmallRainbow class="small-rainbow rb-two" />
    <SmallRainbow class="small-rainbow rb-three" />
    <Triangle class="triangle t-one" />
    <Triangle class="triangle t-two" />
  </div>
</template>

<script>
import { gsap } from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import CityBackdrop from "@/svgs/ctg3/city-backdrop.svg";
import BirdOnScooter from "@/svgs/ctg3/bird-on-scooter.svg";
import SmallRainbow from "@/svgs/ctg3/small-rainbow.svg";
import Triangle from "@/svgs/ctg3/blue-triangle.svg";

export default {
  components: {
    CityBackdrop,
    BirdOnScooter,
    SmallRainbow,
    Triangle
  },
  data() {
    return {
      check: true
    };
  },
  methods: {
    moveRandom(element, check) {
      if (check) {
        gsap.to(element, 5, {
          xPercent: gsap.utils.random(-100, 100),
          yPercent: gsap.utils.random(-100, 100),
          yoyo: true,
          ease: "linear",
          onComplete: this.moveRandom,
          onCompleteParams: [element, this.check]
        });
      }
    },
    animate() {
      let vw = window.innerWidth;

      gsap.to(".bird-on-scooter", 10, {
        x: vw + 100,
        repeat: -1,
        ease: "linear"
      });
      let birdTl = gsap.timeline({ repeat: -1 });
      birdTl.to(".bird-on-scooter", 2, {
        rotation: -45,
        transformOrigin: "center center"
      });
      birdTl.to(".bird-on-scooter", 2, {
        rotation: 0,
        transformOrigin: "center center"
      });
      birdTl.to({}, 2, {});

      let triangles = document.querySelectorAll(".triangle");
      triangles.forEach(triangle => {
        this.moveRandom(triangle, true);
      });

      gsap.to(".triangle", 10, {
        rotation: 360,
        repeat: -1,
        ease: "linear"
      });
      gsap.to(".triangle", 5, {
        scale: 1.5,
        repeat: -1,
        ease: "linear",
        yoyo: true
      });

      let strokeTl = gsap.timeline({ yoyo: true, repeat: -1, ease: "linear" });
      gsap.set(".rb-one", {
        y: 0,
        x: 0
      });
      gsap.to(".rb-one", 40, {
        motionPath: {
          path:
            "M-8,205 C-8,236 105.569,544.704 257,668 365.988,756.738 740,899 771,899",
          alignOrigin: [0.5, 0.5],
          autoRotate: 90
        },
        ease: "linear",
        repeat: -1
      });

      gsap.to(".rb-two", 40, {
        motionPath: {
          path:
            "M1132,744 C1132,775 1223.323,569.943 863.179,409.342 551.701,270.44 587.097,161 615,161",
          alignOrigin: [0.5, 0.5],
          autoRotate: 90
        },
        ease: "linear",
        repeat: -1
      });

      gsap.to(".rb-three", 40, {
        motionPath: {
          path:
            "M-528,1212 C-528,1243 -353.67,1159.744 -186,813 68.466,286.76 -646,-149 -615,-149",
          alignOrigin: [0.5, 0.5],
          autoRotate: 90
        },
        ease: "linear",
        repeat: -1
      });

      gsap.fromTo(
        ".rb-two, .rb-three",
        3,
        {
          rotation: -15
        },
        {
          rotation: 15,
          repeat: -1,
          yoyo: true,
          ease: "linear"
        }
      );

      strokeTl.to(".stroke-one", 4, {
        drawSVG: "0%"
      });
      strokeTl.to(".stroke-two", 4, {
        drawSVG: "0%"
      });
      strokeTl.to(".stroke-three", 4, {
        drawSVG: "0%"
      });
    }
  },
  mounted() {
    gsap.registerPlugin(DrawSVGPlugin, MotionPathPlugin);
    this.animate();
  },
  beforeDestroy() {
    this.check = false;
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media-queries";

.bottom-graphic {
  position: absolute;
  bottom: 0;
  @include mobile {
    height: 100%;
  }
  @include tablet {
    height: 50%;
  }
  @include desktop {
    height: auto;
    width: 100%;
  }
}

.bird-on-scooter {
  position: absolute;
  height: 100px;
  bottom: 20%;
  left: -90px;
}

.small-rainbow {
  position: absolute;
  height: 30px;
  &.rb-three {
    left: 90%;
  }
}
.triangle {
  position: absolute;
  height: 20px;
  &.t-one {
    left: 23%;
    top: 40%;
  }
  &.t-two {
    right: 20%;
    top: 3%;
  }
}
</style>
