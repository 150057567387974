<template>
  <div class="ending-container">
    <EndingWin class="ending-graphic win" v-if="checkIfWin" />
    <EndingFail class="ending-graphic fail" v-else />

    <span v-if="!checkIfWin" class="result fail">
      Nesten! Du fikk {{ getTotalCorrect }} av 13 riktige. <br />
      Trykk på knappen under for å prøve igjen!
    </span>

    <span v-if="checkIfWin" class="result">
     Ta turen innom en Telenor-butikk og få selve merket som er en popsocket du kan feste på mobilen din.
    </span>

    <div class="clip-path-bottom" :class="{ win: checkIfWin }">
      <div class="text-container">
        <span class="tip-text">
          Tips til foreldre: Våre mobilabonnement gir barn en trygg digital hverdag.
          <a
            href="https://www.telenor.no/mobilabonnement/barn"
            target="_blank"
            rel="noopener noreferrer"
            >Se våre abonnement for mobilklare barn.</a
          >

        </span>

        <span class="ending-help-text">
          Kors på halsen er et gratis og anonymt samtaletilbud der barn og unge
          kan snakke med en voksen om det de har på hjertet. Bli kjent med Kors
          på halsen
          <a
            href="https://korspaahalsen.rodekors.no/"
            target="_blank"
            rel="noopener noreferrer"
            >her</a
          >.
        </span>
      </div>

      <button
        class="ending-button single "
        v-if="!checkIfWin"
        @click="this.restart"
      >
        <span>Prøv igjen</span>
      </button>
    </div>
  </div>
</template>

<script>
import EndingWin from "@/svgs/ending-illustration-win.svg";
import EndingFail from "@/svgs/ending-illustration-fail.svg";

import { gsap } from "gsap";

export default {
  components: {
    EndingWin,
    EndingFail
  },
  data() {
    return {
      check: true
    };
  },
  methods: {
    restart() {
      this.$store.dispatch("SET_STATE_FOR_RESET");
    },
    animate() {
      let letters = document.querySelectorAll(".letter");
      if (letters.length > 0) {
        gsap.set(letters, {
          opacity: 0
        });
      }

      let endingButton = document.querySelector(".ending-button");
      if (endingButton !== null) {
        gsap.set(".ending-button", {
          opacity: 0,
          y: 20
        });
      }

      gsap.set(
        ".ending-graphic, .result, .tip-text, .ending-help-text, .clip-path-bottom",
        {
          opacity: 0,
          y: 20
        }
      );
      let tl = gsap.timeline();
      tl.to({}, 0.5, {});

      tl.to(
        ".ending-graphic",
        0.5,
        {
          y: 0,
          opacity: 1
        },
        "-=0.2"
      );
      if (letters.length > 0) {
        letters.forEach(letter => {
          tl.to(letter, 0.1, {
            opacity: 1
          });
        });
      }

      tl.to(
        ".result",
        0.5,
        {
          y: 0,
          opacity: 1
        },
        "-=0.2"
      );
      tl.to(
        ".clip-path-bottom",
        0.5,
        {
          y: 0,
          opacity: 1
        },
        "-=0.2"
      );
      tl.to(
        ".tip-text",
        0.5,
        {
          y: 0,
          opacity: 1
        },
        "-=0.2"
      );
      tl.to(
        ".ending-help-text",
        0.5,
        {
          y: 0,
          opacity: 1
        },
        "-=0.2"
      );
      if (endingButton !== null) {
        tl.to(
          ".ending-button",
          0.5,
          {
            y: 0,
            opacity: 1
          },
          "-=0.2"
        );
      }

      let strokes = document.querySelector(".strokes-merket");
      if (strokes !== null) {
        gsap.to(strokes, 1, {
          drawSVG: 0,
          yoyo: true,
          repeat: -1
        });
      }
    }
  },
  computed: {
    getTotalCorrect() {
      return this.$store.getters.totalCorrect;
    },
    checkIfWin() {
      if (this.getTotalCorrect >= 10) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.animate();
  },
  beforeDestroy() {
    this.check = false;
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/media-queries";
a {
  color: inherit;
  &.reset {
    cursor: pointer;
    text-decoration: underline;
  }
}
.letters {
  position: relative;
  transform: translate(15%, 5%);
}

.ending-container {
  display: flex;
  flex-direction: column;
  place-items: center;
  height: 100%;
  text-align: center;

  @include mobile {
    font-size: 16px;
  }

  .ending-graphic {
    overflow: initial;
    max-width: 600px;
    width: 90%;
    max-width: 70vw;
    margin: 0 auto;
    margin-bottom: 10px;
    margin-top: -50px;

    &.win {
      margin-top: 0;
    }

    @include removeGraphic {
      display: none;
    }
    @include smallHeight {
      width: 60%;
    }

    @include tablet {
      margin-top: 0;
      max-width: 500px;
      @include height1000 {
        height: 40vh;
      }

      @include mediumHeight {
        height: 30vh;
      }
    }
    @include desktop {
      width: auto;
      max-height: 45vh;
    }
  }

  .result {
    margin-top: 10px;
    max-width: 600px;
    width: 90%;

    &.fail {
      margin-top: -10px;
    }

    @include tablet {
      margin-top: 40px;
      width: 80%;
      @include portrait {
        margin-top: 40px;
        margin-bottom: 30px;
      }
    }
    @include font-size-description();
  }
}

.clip-path-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  background: #00C8FF;
  width: 100vw;
  clip-path: polygon(0 6%, 100% 0, 100% 100%, 0% 100%);
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: space-between;
  height: auto;

  .text-container {
    margin-top: 40px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    font-size: 12px;
    max-width: 80%;

    @include tablet {
      flex-direction: row;
      font-size: 16px;
      margin-top: 80px;
      margin-bottom: 60px;
      column-gap: 60px;
    }
    @include desktop {
      font-size: 16px;
      margin-top: 50px;
      margin-bottom: 40px;
    }
  }

  .tip-text {
    max-width: 350px;
    margin-bottom: 20px;
    @include tablet {
      flex: 1;
      margin-bottom: 0;
    }
  }

  .ending-help-text {
    max-width: 350px;
    @include tablet {
      flex: 1;
    }
  }
  .ending-button {
    margin: 0 20px 27px 20px;
    width: 90%;
    max-width: 400px;
    @include tablet {
      margin-bottom: 60px;
    }

    &.win {
      display: none;
    }
  }
}
</style>
