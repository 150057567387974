<template>
  <div>
    <ConstellationOne class="constellation c-one" />
    <ConstellationTwo class="constellation c-two" />
    <ConstellationThree class="constellation c-three" />
    <BalloonMan class="balloon-man" />
    <DarkBottom class="bottom-graphic lower" />
  </div>
</template>

<script>
import { gsap } from "gsap";
import ConstellationOne from "@/svgs/ctg2/constellation-one.svg";
import ConstellationTwo from "@/svgs/ctg2/constellation-two.svg";
import ConstellationThree from "@/svgs/ctg2/constellation-three.svg";
import BalloonMan from "@/svgs/ctg2/balloon-man.svg";
import DarkBottom from "@/svgs/ctg2/bottom-dark.svg";

export default {
  components: {
    ConstellationOne,
    ConstellationTwo,
    ConstellationThree,
    BalloonMan,
    DarkBottom
  },
  methods: {
    animate() {
      let vh = window.innerHeight;
      let vw = window.innerWidth;
      gsap.set(".balloon-man", {
        y: vh / 2.8,
        x: vw / 7
      });
      gsap.to(".balloon-man", 40, {
        y: -300,
        ease: "linear"
      });
      gsap.fromTo(
        ".constellation",
        10,
        {
          scale: 1
        },
        {
          scale: 0.6,
          yoyo: true,
          repeat: -1
        }
      );
    }
  },
  mounted() {
    this.animate();
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media-queries.scss";

.bottom-graphic {
  position: absolute;
  width: 300%;

  &.lower {
    bottom: 0;
    max-height: 400px;

    @include tablet {
      max-height: 600px;
      bottom: -10%;
    }
    @include desktop {
      width: 125%;
      max-height: 800px;
      bottom: -30%;
    }
    @include widescreen {
      max-height: 1300px;
    }
  }
}

.balloon-man {
  position: absolute;
  z-index: 0;
  height: 100px;
  @include tablet {
    height: 150px;
  }
  @include desktop {
    height: 150px;
  }
}

.constellation {
  position: absolute;
  height: 50px;
  overflow: initial;

  @include tablet {
    height: 100px;
  }
  &.c-one {
    display: none;
    @include desktop {
      display: initial;
    }
    top: 35%;
    left: 5%;
  }
  &.c-two {
    right: 40%;
    top: -10%;
  }
  &.c-three {
    right: 10%;
    top: 40%;
  }
}
</style>
