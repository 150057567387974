<template>
  <div class="start-container">
    <Ufo class="ufo" />
    <RedPlanet class="red-planet" />
    <PauseVolume
      class="volume-svg pause start-screen"
      @click="playAudio"
      :class="checkTheme"
      v-if="this.playing"
    />

    <VolumeButton
      class="volume-svg start-screen"
      @click="playAudio"
      :class="checkTheme"
      v-else
    />

    <audio ref="audio" class="hidden-audio" @ended="audioEnded">
      <source :src="this.localAudio" type="audio/mp3" />
    </audio>

    <div class="info-container" v-if="!typeName">
      <div class="illustration-container">
        <MobileStartIllustration
          class="start-illustration gsap-item"
          v-if="mobileView && !typeName"
        />
        <StartIllustration
          class="start-illustration gsap-item"
          v-if="!mobileView && !typeName"
        />
      </div>
      <span class="start-text gsap-item" v-if="!typeName">
        Skal du få ny mobil? Vi er sikre på at du er stor nok, men ta
        Mobilmerket og vis en voksen at du forstår hvordan den behandles.</span
      >
      <div class="small-text gsap-item" v-if="!typeName">
        Spørsmålene er laget i samarbeid med Kors på halsen.
      </div>

      <div class="card">
        <div class="content">
          <label
            class="modal-open modal-label gsap-item"
            v-if="!typeName"
            for="modal-open"
            >Våre informasjonskapsler</label
          >
          <input
            type="radio"
            name="modal"
            value="open"
            id="modal-open"
            class="modal-radio"
          />

          <div class="modal">
            <label class="modal-label overlay"
              ><input
                type="radio"
                name="modal"
                value="close"
                id="modal-open"
                class="modal"
            /></label>
            <div class="content">
              <div class="top">
                <label class="modal-label-close close-btn">
                  <input
                    type="radio"
                    name="modal"
                    value="close"
                    class="modal-radio"
                  />
                </label>
              </div>

              <p class="modal_content">
                Vi bruker informasjonskapsler til å samle inn informasjon om
                hvor mange brukere som tar Mobilmerket og fullfører testen.
                Ønsker du å endre innstillinger, kan dette gjøres i din
                nettleser.
              </p>

              <div class="mobile-footer">
                <center>
                  <label class="modal-label close-btn-botton"
                    ><input
                      type="radio"
                      name="modal"
                      value="close"
                      class="modal-botton"
                  /></label>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button v-if="!typeName" class="start-button single" @click="goToName">
      <span>Ta Mobilmerket</span>
    </button>

    <div class="middle" v-if="typeName">
      <span class="question-text">Først, hva heter du?</span>
      <div class="input-container">
        <input
          type="text"
          name="input-name"
          @keyup="updateName"
          ref="name"
          @keyup.enter="animateStart"
          minlength="2"
          maxlength="16"
        />
      </div>
    </div>

    <button
      class="start-button single"
      @click="animateStart"
      v-if="!showError && typeName"
    >
      <span>Ta meg til første tema</span>
    </button>
    <button v-if="typeName && showError" class="hidden-button">
      <span>Du må skrive inn navn!</span>
    </button>
  </div>
</template>

<script>
import {gsap} from "gsap";
import {DrawSVGPlugin} from "gsap/DrawSVGPlugin";
import StartIllustration from "@/svgs/big-start-illustration.svg";
import MobileStartIllustration from "@/svgs/small-start-illustration.svg";
import RedPlanet from "@/svgs/ctg2/red-planet.svg";
import Ufo from "@/svgs/ctg2/ufo.svg";
import {createUser} from "../axios";
import VolumeButton from "@/svgs/volume.svg";
import PauseVolume from "@/svgs/pause.svg";
import Audio from "@/assets/audio/START_1.wav";
import Audio_2 from "@/assets/audio/START_2.wav";

export default {
  data() {
    return {
      typeName: false,
      showError: true,
      mobileView: null,
      check: true,
      localAudio: Audio,
      playing: false,
    };
  },
  components: {
    StartIllustration,
    MobileStartIllustration,
    Ufo,
    RedPlanet,
    VolumeButton,
    PauseVolume,
  },
  created() {
    window.addEventListener("resize", this.checkMobileView);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkMobileView);
  },
  beforeDestroy() {
    this.check = false;
  },
  computed: {
    getTheme() {
      return this.$store.getters.currentTheme;
    },
    checkTheme() {
      let theme = this.getTheme;
      if (theme.includes("water")) {
        if (theme.includes("dark")) {
          return "water dark";
        }
        return "water light";
      } else if (theme.includes("space")) {
        if (theme.includes("dark")) {
          return "space dark";
        }
        return "space light";
      } else if (theme.includes("city")) {
        if (theme.includes("dark")) {
          return "city dark";
        }
        return "city light";
      }
      return "";
    },
  },
  methods: {
    audioEnded() {
      this.playing = false;
    },
    playAudio() {
      let audio = this.$refs.audio;
      if (audio.paused) {
        this.playing = true;
        audio.play();
      } else {
        this.playing = false;
        audio.pause();
      }
    },
    moveRandom(element, check, percent) {
      if (check) {
        gsap.to(element, 5, {
          xPercent: gsap.utils.random(-percent, percent),
          yPercent: gsap.utils.random(-percent, percent),
          yoyo: true,
          ease: "linear",
          onComplete: this.moveRandom,
          onCompleteParams: [element, this.check, percent],
        });
      }
    },
    animateStart() {
      if (!this.showError) {
        let tl = gsap.timeline({
          onComplete: function() {
            this.$store.dispatch("SET_SHOW_START");
            this.$store.dispatch("SET_SHOW_CTG_START");
            this.$store.dispatch("SET_CURRENT_THEME", "water");
          },
          callbackScope: this,
        });

        tl.set("#mask", {
          width: "100vw",
          height: "0vh",
          zIndex: 10,
        });

        tl.to("#mask", 0.5, {
          height: "100vh",
        });
      }
    },
    animate() {
      gsap.set(".start-text, .modal-label, .small-text, .start-button", {
        opacity: 0,
        y: 20,
      });
      gsap.set(".start-button", {
        pointerEvents: "none",
      });
      if (this.mobileView) {
        gsap.set(".left-girl, .cloud, .mobil-merket, .billboard, .letter", {
          opacity: 0,
          scale: 0,
          transformOrigin: "25%",
        });
        gsap.set(".right-dude", {
          opacity: 0,
        });
      } else {
        gsap.set(
          ".right-dude, .left-girl, .cloud, .mobil-merket, .billboard, .ufo, .red-planet, .letter",
          {
            opacity: 0,
            scale: 0,
            transformOrigin: "center",
          },
        );
      }
      gsap.set(".merket-snakes", {
        drawSVG: 0,
      });
      gsap.set(".mobil-merket", {
        rotation: -120,
      });
      if (!this.mobileView) {
        gsap.set(".right-dude", {
          x: -100,
          scale: 0.5,
          opacity: 0,
        });
      }
      gsap.set(".left-girl", {
        x: 100,
        scale: 0.5,
      });

      let tl = gsap.timeline();
      tl.to({}, 0.5, {});

      tl.to(".cloud", 0.5, {
        opacity: 1,
        scale: 1,
        ease: "bounce",
      });
      tl.to(
        ".billboard",
        0.5,
        {
          opacity: 1,
          scale: 1,
        },
        "-=0.2",
      );
      if (this.mobileView) {
        gsap.set(".mobil-merket", {
          x: -80,
          y: -60,
        });
        tl.to(
          ".mobil-merket",
          0.5,
          {
            opacity: 1,
            scale: 1,
            rotation: 0,
            ease: "linear",
            transformOrigin: "center center",
          },
          "-=0.2",
        );
      } else {
        tl.to(
          ".mobil-merket",
          0.5,
          {
            opacity: 1,
            scale: 1,
            rotation: 0,
            ease: "linear",
            transformOrigin: "center",
          },
          "-=0.2",
        );
      }
      let letters = document.querySelectorAll(".letter");
      letters.forEach((letter) => {
        tl.to(
          letter,
          0.1,
          {
            opacity: 1,
            scale: 1,
          },
          "-=0.05",
        );
      });
      if (!this.mobileView) {
        tl.to(
          ".right-dude",
          0.5,
          {
            x: 0,
            scale: 1,
            opacity: 1,
            // transformOrigin: "100% 100%"
          },
          "-=0.2",
        );
      } else {
        tl.to(".right-dude", 0.5, {
          opacity: 1,
        });
      }
      tl.to(
        ".left-girl",
        0.5,
        {
          x: 0,
          scale: 1,
          opacity: 1,
        },
        "-=0.5",
      );
      tl.to(
        ".red-planet, .ufo",
        0.5,
        {
          scale: 1,
          opacity: 1,
        },
        "-=0.2",
      );
      tl.to(
        ".merket-snakes",
        1,
        {
          drawSVG: 100,
        },
        "-=1",
      );

      tl.to(
        ".start-text",
        0.5,
        {
          y: 0,
          opacity: 1,
        },
        "-=0.2",
      );
      tl.to(
        ".small-text",
        0.5,
        {
          y: 0,
          opacity: 1,
        },
        "-=0.2",
      );

      tl.to(
        ".modal-label",
        0.5,
        {
          y: 0,
          opacity: 1,
        },
        "-=0.2",
      );

      tl.to(
        ".start-button",
        0.5,
        {
          y: 0,
          opacity: 1,
          pointerEvents: "all",
        },
        "-=0.2",
      );

      let leftGirl = document.querySelector(".left-girl");
      let rightDude = document.querySelector(".right-dude");
      let merket = document.querySelector(".mobil-merket");
      let billboard = document.querySelector(".billboard");
      let cloud = document.querySelector(".cloud");
      this.moveRandom(leftGirl, true, 2.5);
      this.moveRandom(rightDude, true, 2.5);
      this.moveRandom(merket, true, 1.5);
      this.moveRandom(billboard, true, 1.5);
      this.moveRandom(cloud, true, 1.5);

      //External from start-illustration
      let ufo = document.querySelector(".ufo");
      let planet = document.querySelector(".red-planet");
      this.moveRandom(ufo, true, 10);
      this.moveRandom(planet, true, 10);
    },
    checkMobileView() {
      let vw = window.innerWidth;
      if (vw > 767) {
        this.mobileView = false;
      } else {
        this.mobileView = true;
      }
    },
    animateInputName() {
      this.$nextTick(() => {
        gsap.fromTo(
          ".middle, .question-text, .input-container",
          0.5,
          {
            y: 20,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
          },
        );
      });
    },
    updateName(event) {
      let name = event.target.value;
      if (name.length < 2) {
        this.showError = true;
      } else {
        this.showError = false;
        this.$store.dispatch("SET_NAME", name);
      }
    },
    focusInput() {
      this.$nextTick(() => {
        this.$refs.name.focus();
      });
    },
    goToName() {
      this.localAudio = Audio_2;
      this.audioEnded();
      this.$refs.audio.load();
      fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((response) => {
          this.clientIp = response.ip;
          let data = {ip: response.ip};
          createUser(data).then((data) => {
            this.$emit("createUser", data.user);
          });
        });
      const tl = gsap.timeline({
        onComplete: function() {
          this.typeName = true;
          this.focusInput();
          this.animateInputName();
        },
        callbackScope: this,
      });
      tl.to(".gsap-item", 0.5, {
        y: 20,
        opacity: 0,
      });
    },
  },
  mounted() {
    gsap.registerPlugin(DrawSVGPlugin);
    this.checkMobileView();
    this.animate();
    window.addEventListener("scroll", () => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/media-queries.scss";

.ufo {
  height: 50px;
  position: absolute;
  top: 0;
  left: 10%;
  @include mobile {
    display: none;
  }
}

.red-planet {
  height: 50px;
  position: absolute;
  top: 10%;
  right: 5%;
  @include mobile {
    display: none;
  }
}

.letters {
  transform: translate(33%, 10%);
  @include mobile {
    transform: translate(31%, 8%);
  }
}

.start-container {
  height: calc(100% - 27px);
  text-align: center;

  display: grid;
  place-items: center;
  grid-template-rows: repeat(3, 1fr) max-content;

  @include tablet {
    height: calc(100% - 60px);
    grid-template-rows: repeat(3, 1fr) max-content;
    @include mediumHeight {
      gap: 0px;
    }
  }

  button {
    grid-column: 1;
    grid-row: 4 / 5;
    margin-top: auto;
    @include tablet {
      @include height1024 {
      }
    }
    @include desktop {
      margin-bottom: 0;
    }
  }

  .info-container {
    grid-row: 1 / 4;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -6vh;
    max-width: 90%;
    height: 100%;
  }

  .illustration-container {
    flex: 1;
width:100%;
  }

  .start-illustration {
    height: 100%;
    overflow: initial;
    max-width: 100%;

    @include removeGraphic {
      display: none;
    }

    @include tablet {
      @include portrait {
        margin-bottom: 0px;
      }
    }

    @include desktop {
      width: auto;
      max-width: 800px;
      margin-top: 0;
      margin-bottom: 30px;
    }
  }

  .start-text {
    margin-bottom: 20px;
    margin-top: 10px;

    @include smallHeight {
      font-size: 14px;
    }

    @include mobile {
      max-width: 600px;
    }

    @include tablet {
      width: 80%;
      @include portrait {
        margin-top: 40px;
        margin-bottom: 30px;
      }
    }
    max-width: 800px;
    @include font-size-description();
  }

  .small-text {
    font-size: 14px;
    @include smallHeight {
      font-size: 12px;
    }
    @include desktop {
      font-size: 16px;
    }
    width: 80%;
  }

  .middle {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
    max-width: 500px;
    width: 80%;
    grid-row: 1 / 5;
    grid-column: 1;
    place-self: center;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    label {
      width: max-content;
      font-size: 14px;
    }
  }

  .question-text {
    margin-bottom: 20px;
    display: block;
  }

  input {
    padding: 10px 20px;
    border: 4px solid #eada9b;
    width: 100%;
    @include font-size-question();
    -webkit-appearance: none;
  }
  .input-mask {
    position: absolute;
    width: 100vw;
    top: 100%;
    height: 40vh;
    z-index: -100;
    background: #fbd4d5;
  }
}

.hidden-audio {
  position: absolute;
  left: -9999px;
}
</style>
