<template>
  <div>
    <svg width="100%" height="100%" class="path-container">
      <path
        fill="none"
        id="donut-path1"
        d="M0,0C0,31,583.161,22.747,673.999,368,814.355,901.461,1868,221,1899,221"
      />
    </svg>
    <svg class="path-container four">
      <path
        fill="none"
        id="donut-path2"
        d="M-528,1212 C-528,1243 -353.67,1159.744 -186,813 68.466,286.76 -646,-149 -615,-149"
      />
    </svg>
    <CityBackdrop class="bottom-graphic" />
    <Donut class="donut d-one" />
    <Donut class="donut d-two" />
  </div>
</template>

<script>
import CityBackdrop from "@/svgs/ctg3/city-backdrop.svg";
import Donut from "@/svgs/ctg3/donut.svg";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

export default {
  components: {
    CityBackdrop,
    Donut
  },
  methods: {
    animate() {
      gsap.to(".donut", 10, {
        rotation: 360,
        ease: "linear",
        repeat: -1
      });

      gsap.to(".d-one", 40, {
        motionPath: {
          path: "#donut-path1",
          align: "#donut-path1"
        },
        ease: "linear",
        repeat: -1
      });
      gsap.to(".d-two", 40, {
        motionPath: {
          path: "#donut-path2",
          align: "#donut-path2"
        },
        ease: "linear",
        repeat: -1
      });
    }
  },
  mounted() {
    gsap.registerPlugin(MotionPathPlugin);
    this.animate();
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media-queries.scss";
.path-container {
  position: absolute;
  &.four {
    left: 90%;
  }
}
.bottom-graphic {
  position: absolute;
  bottom: 0;
  @include mobile {
    height: 100%;
  }
  @include tablet {
    height: 50%;
  }
  @include desktop {
    height: auto;
    width: 100%;
  }
}

.donut {
  height: 40px;
  position: absolute;
}
</style>
