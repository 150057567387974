<template>
  <div>
    <SeaHorse class="sea-horse" />
    <Fish class="fish" />
    <Fish class="fish" />
    <Fish class="fish" />
    <Fish class="fish" />
  </div>
</template>

<script>
import { gsap } from "gsap";
import SeaHorse from "@/svgs/ctg1/sea-horse.svg";
import Fish from "@/svgs/ctg1/fish.svg";
export default {
  components: {
    SeaHorse,
    Fish
  },
  data() {
    return {
      check: true
    };
  },
  methods: {
    animateSeaHorse() {
      let vh = window.innerHeight;
      let vw = window.innerWidth;

      let seaHorse = document.querySelector(".sea-horse");
      if (seaHorse !== null) {
        this.moveRandom(seaHorse, true);
        let seaHorseTl = gsap.timeline({ repeat: -1 });
        seaHorseTl.set(".sea-horse", {
          y: gsap.utils.random(0, vh - "70px"),
          x: vw + 50
        });
        seaHorseTl.to(".sea-horse", 30, {
          x: -50,
          ease: "linear"
        });
      }
    },
    moveRandom(element, check) {
      if (check) {
        gsap.to(element, gsap.utils.random(10, 15), {
          yPercent: gsap.utils.random(-200, 200),
          ease: "linear",
          onComplete: this.moveRandom,
          onCompleteParams: [element, this.check]
        });
      }
    },
    animateFish() {
      let vh = window.innerHeight;
      let vw = window.innerWidth;
      gsap.fromTo(
        ".fish, .sea-horse",
        2,
        {
          rotation: -10
        },
        {
          rotation: 10,
          repeat: -1,
          yoyo: true,
          ease: "linear"
        }
      );

      let fishes = document.querySelectorAll(".fish");
      fishes.forEach(fish => {
        this.moveRandom(fish, true);
        let fishtl = gsap.timeline({ repeat: -1 });
        fishtl.set(fish, {
          x: vw,
          y: gsap.utils.random(0, vh - 100)
        });
        fishtl.to(fish, gsap.utils.random(30, 50), {
          x: -100,
          ease: "linear"
        });
      });
    }
  },
  mounted() {
    this.animateSeaHorse();
    this.animateFish();
  },
  beforeDestroy() {
    this.check = false;
  }
};
</script>

<style lang="scss" scoped>
.sea-horse {
  height: 70px;
}
.fish {
  width: 50px;
  position: absolute;
}
</style>
