<template>
  <div>
    <ConstellationOne class="constellation c-one" />
    <ConstellationTwo class="constellation c-two" />
    <Ufo class="ufo" />
    <Star class="star s-one" />
    <Star class="star s-two" />
    <Star class="star s-three desktop" />
    <Star class="star s-four desktop" />
    <Star class="star s-five desktop" />
    <Star class="star s-six desktop" />
    <Star class="star s-seven desktop" />
  </div>
</template>

<script>
import { gsap } from "gsap";
import ConstellationOne from "@/svgs/ctg2/constellation-one.svg";
import ConstellationTwo from "@/svgs/ctg2/constellation-two.svg";
import Ufo from "@/svgs/ctg2/ufo.svg";
import Star from "@/svgs/ctg2/star.svg";

export default {
  components: {
    ConstellationOne,
    ConstellationTwo,
    Ufo,
    Star
  },
  data() {
    return {
      check: true
    };
  },
  methods: {
    moveRandom(element, check) {
      if (check) {
        gsap.to(element, 10, {
          xPercent: gsap.utils.random(-5, 50),
          yPercent: gsap.utils.random(-5, 50),
          yoyo: true,
          ease: "linear",
          onComplete: this.moveRandom,
          onCompleteParams: [element, this.check]
        });
      }
    },
    animate() {
      let vw = window.innerWidth;
      let vh = window.innerHeight;
      gsap.fromTo(
        ".star",
        10,
        {
          scale: 1
        },
        {
          scale: 0.5,
          repeat: -1,
          yoyo: true,
          ease: "linear"
        }
      );
      gsap.to(".ufo", 5, {
        scale: 1.4,
        repeat: -1,
        yoyo: true,
        ease: "linear"
      });
      let ufo = document.querySelector(".ufo");
      this.moveRandom(ufo, true);
      let stars = document.querySelectorAll(".star");
      stars.forEach(star => {
        this.moveRandom(star, true);
      });
      gsap.set(".c-one", {
        x: -150,
        y: vh / 1.5
      });
      gsap.set(".c-two", {
        x: vw,
        y: vh / 5
      });
      gsap.to(".c-one", 60, {
        x: vw,
        ease: "linear",
        rotation: 360,
        repeat: -1
      });
      gsap.to(".c-two", 60, {
        x: -150,
        ease: "linear",
        rotation: 360,
        repeat: -1
      });
    }
  },
  mounted() {
    this.animate();
  },
  beforeDestroy() {
    this.check = false;
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media-queries.scss";

.constellation {
  position: absolute;
  height: 50px;
  @include tablet {
    height: 100px;
  }
  &.c-two {
    @include mobile {
      display: none;
    }
  }
}

.ufo {
  position: absolute;
  height: 25px;
  right: 5%;
  top: 15%;
  transform: translate(-50%);
  @include mobile {
  }
}

.star {
  position: absolute;
  height: 30px;
  &.s-one {
    left: 5%;
    @include mobile {
      display: none;
    }
  }
  &.s-two {
    right: 15%;
    top: 60%;
    height: 35px;
  }
  &.s-three {
    left: 58%;
    top: 20%;
    height: 65px;
  }
  &.s-four {
    left: 35%;
    top: -5%;
    height: 35px;
  }
  &.s-five {
    top: 60%;
    left: 5%;
  }
  &.s-six {
    left: 50%;
    top: 70%;
    height: 50px;
  }
  &.s-seven {
    right: 15%;
  }
  &.desktop {
    @include mobile {
      display: none;
    }
  }
}
</style>
