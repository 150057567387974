<template>
  <One v-if="remaining === 1" :class="checkTheme" />
  <Two v-else-if="remaining === 2" :class="checkTheme" />
  <Three v-else-if="remaining === 3" :class="checkTheme" />
  <Four v-else-if="remaining === 4" :class="checkTheme" />
  <Five v-else-if="remaining === 5" :class="checkTheme" />
  <Six v-else-if="remaining === 6" :class="checkTheme" />
  <Seven v-else-if="remaining === 7" :class="checkTheme" />
  <Eight v-else-if="remaining === 8" :class="checkTheme" />
  <Nine v-else-if="remaining === 9" :class="checkTheme" />
  <Ten v-else-if="remaining === 10" :class="checkTheme" />
  <Eleven v-else-if="remaining === 11" :class="checkTheme" />
  <Twelve v-else-if="remaining === 12" :class="checkTheme" />
  <Thirteen v-else-if="remaining === 13" :class="checkTheme" />
</template>

<script>
import One from "@/svgs/header/1.svg";
import Two from "@/svgs/header/2.svg";
import Three from "@/svgs/header/3.svg";
import Four from "@/svgs/header/4.svg";
import Five from "@/svgs/header/5.svg";
import Six from "@/svgs/header/6.svg";
import Seven from "@/svgs/header/7.svg";
import Eight from "@/svgs/header/8.svg";
import Nine from "@/svgs/header/9.svg";
import Ten from "@/svgs/header/10.svg";
import Eleven from "@/svgs/header/11.svg";
import Twelve from "@/svgs/header/12.svg";
import Thirteen from "@/svgs/header/13.svg";

export default {
  props: {
    remaining: {
      type: Number,
      required: true
    }
  },
  components: {
    One,
    Two,
    Three,
    Four,
    Five,
    Six,
    Seven,
    Eight,
    Nine,
    Ten,
    Eleven,
    Twelve,
    Thirteen
  },
  computed: {
    checkTheme() {
      return this.$store.getters.currentTheme;
    }
  }
};
</script>

<style lang="scss" scoped>
.dark {
  path {
    fill: #fff;
  }
}
</style>
