<template>
  <div>
    <svg class="path-container">
      <path
        fill="none"
        id="path1"
        d="M449,-400 C449,-369 1.823,-283.345 24,75 50.298,499.949 257.44,497.951 585.75862,414.94688 1036.169,301.075 2280.949,916 2298,916"
      />
    </svg>
    <svg width="100%" height="100%" class="path-container">
      <path
        fill="none"
        id="path2"
        d="M826,1209 C826,1240 1223.323,569.943 863.179,409.342 551.701,270.44 706.097,-7 734,-7"
      />
    </svg>
    <DarkBottom class="bottom-graphic" />
    <IceCream class="ice-cream ic-one" />
    <IceCream class="ice-cream ic-two" />
    <Rainbow class="rainbow rb-one" />
    <Rainbow class="rainbow rb-two" />
  </div>
</template>

<script>
import DarkBottom from "@/svgs/ctg3/dark-bottom.svg";
import IceCream from "@/svgs/ctg3/ice-cream.svg";
import Rainbow from "@/svgs/ctg3/small-rainbow.svg";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

export default {
  components: {
    DarkBottom,
    IceCream,
    Rainbow
  },
  methods: {
    animate() {
      gsap.to(".ic-one", 5, {
        rotation: 50,
        yoyo: true,
        repeat: -1
      });
      gsap.to(".ic-two", 5, {
        rotation: -20,
        yoyo: true,
        repeat: -1
      });
      gsap.to(".rainbow", 5, {
        opacity: 0.1,
        yoyo: true,
        repeat: -1
      });
      gsap.to(".ic-one", 40, {
        motionPath: {
          path: "#path1",
          align: "#path1",
          alignOrigin: [0.5, 0.5],
          autoRotate: 90
        },
        ease: "linear",
        repeat: -1
      });
      gsap.to(".ic-two", 40, {
        motionPath: {
          path: "#path2",
          align: "#path2",
          alignOrigin: [0.5, 0.5],
          autoRotate: 90
        },
        ease: "linear",
        repeat: -1
      });
    }
  },
  mounted() {
    gsap.registerPlugin(MotionPathPlugin);
    this.animate();
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media-queries";
.path-container {
  position: absolute;
}
.rainbow * {
  stroke: #a08e72;
}
.rainbow {
  position: absolute;
  height: 20px;
  &.rb-one {
    left: 10%;
    top: 30%;
  }
  &.rb-two {
    @include mobile {
      display: none;
    }
    right: 10%;
    top: 60%;
  }
}

.bottom-graphic {
  position: absolute;
  bottom: 0;
  @include mobile {
    height: 100%;
  }
  @include tablet {
    width: 100%;
    height: auto;
  }
}

.ice-cream {
  position: absolute;
  height: 50px;
  &.ic-one {
    top: 65%;
    left: 7%;
  }
  &.ic-two {
    top: 22%;
    right: 15%;
    transform: rotate(60deg);
    @include tablet {
      top: 30%;
    }
  }
}
</style>
