<template>
  <div>
    <svg class="path-container">
      <path
        fill="none"
        id="path1"
        d="M449,-400 C449,-369 1.823,-283.345 24,75 50.298,499.949 257.44,497.951 585.75862,414.94688 1036.169,301.075 2280.949,916 2298,916"
      />
    </svg>
    <svg width="100%" height="100%" class="path-container">
      <path
        fill="none"
        id="path2"
        d="M826,1209 C826,1240 1223.323,569.943 863.179,409.342 551.701,270.44 706.097,-7 734,-7"
      />
    </svg>
    <svg class="path-container four">
      <path
        fill="none"
        id="path3"
        d="M-528,1212 C-528,1243 -353.67,1159.744 -186,813 68.466,286.76 -646,-149 -615,-149"
      />
    </svg>
    <CityBackdrop class="bottom-graphic" />
    <Cup class="cup" />
    <Triangle class="triangle t-one" />
    <Triangle class="triangle t-two" />
  </div>
</template>

<script>
import CityBackdrop from "@/svgs/ctg3/city-backdrop.svg";
import Cup from "@/svgs/ctg3/cup.svg";
import Triangle from "@/svgs/ctg3/blue-triangle.svg";
import { gsap } from "gsap";

export default {
  components: {
    CityBackdrop,
    Cup,
    Triangle
  },
  methods: {
    animate() {
      let triangles = document.querySelectorAll(".triangle");
      triangles.forEach(triangle => {
        gsap.to(triangle, gsap.utils.random(7, 12), {
          rotation: 360,
          ease: "linear",
          repeat: -1,
          scale: 1.4,
          yoyo: true
        });
      });
      gsap.to(".cup", 40, {
        motionPath: {
          path: "#path1",
          align: "#path1",
          alignOrigin: [0.5, 0.5],
          autoRotate: 90
        },
        ease: "linear",
        repeat: -1
      });
      gsap.to(".t-one", 40, {
        motionPath: {
          path: "#path2",
          align: "#path2"
        },
        ease: "linear",
        repeat: -1
      });
      gsap.to(".t-two", 40, {
        motionPath: {
          path: "#path3",
          align: "#path3"
        },
        ease: "linear",
        repeat: -1
      });
    }
  },
  mounted() {
    this.animate();
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media-queries.scss";
.path-container {
  position: absolute;
  &.four {
    left: 90%;
  }
}

.bottom-graphic {
  path {
    fill: #504b3c;
  }
  position: absolute;
  bottom: 0;
  @include mobile {
    height: 100%;
  }
  @include tablet {
    height: 50%;
  }
  @include desktop {
    height: auto;
    width: 100%;
  }
}

.cup {
  position: absolute;
  height: 60px;
}

.triangle {
  position: absolute;
  height: 25px;
}
</style>
