<template>
  <div>
    <UpperSand class="bottom-graphic upper" />
    <LowerSand class="bottom-graphic lower" />
    <SmallWave class="wave small" />
    <SmallWave class="wave big" />
  </div>
</template>

<script>
import { gsap } from "gsap";
import LowerSand from "@/svgs/ctg1/lower-sand.svg";
import UpperSand from "@/svgs/ctg1/upper-sand.svg";
import SmallWave from "@/svgs/ctg1/small-wave.svg";

export default {
  components: {
    LowerSand,
    UpperSand,
    SmallWave
  },
  methods: {
    animateSand() {
      gsap.to(".upper", gsap.utils.random(20, 30), {
        xPercent: -10,
        repeat: -1,
        yoyo: true,
        ease: "linear"
      });

      gsap.to(".lower", gsap.utils.random(30, 40), {
        xPercent: -30,
        repeat: -1,
        yoyo: true,
        ease: "linear"
      });
    },
    animateSmallWaves() {
      let vw = window.innerWidth;
      let bigWaveTl = gsap.timeline({ repeat: -1 });
      let smallWaveTl = gsap.timeline({ repeat: -1 });

      bigWaveTl.set(".big", {
        x: -100
      });
      bigWaveTl.to(".big", gsap.utils.random(45, 50), {
        x: vw + 100,
        ease: "linear",
        yoyo: true,
        repeat: -1
      });

      smallWaveTl.set(".small", {
        x: -50
      });
      smallWaveTl.to(".small", gsap.utils.random(30, 35), {
        x: vw + 50,
        ease: "linear"
      });
    },
    enter() {
      let tl = gsap.timeline({
        onComplete() {
          this.animateSand();
          this.animateSmallWaves();
        },
        callbackScope: this
      });
      tl.set(".bottom-graphic", {
        opacity: 0,
        pointerEvents: "none"
      });
      tl.fromTo(
        ".bottom-graphic",
        0.5,
        {
          y: "20%"
        },
        {
          y: "0%",
          opacity: 1,
          pointerEvents: "all"
        }
      );
    }
  },
  mounted() {
    this.enter();
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media-queries";
.wave {
  position: absolute;

  &.small {
    top: 10%;
    width: 50px;
    transform: translateX(-50px);
  }
  &.big {
    top: 30%;
    width: 100px;
    transform: translateX(-100px);
  }
}
.bottom-graphic {
  position: absolute;
  width: 300%;

  &.upper {
    bottom: 10%;
    transform: translateX(-30%);
    max-height: 400px;
    @include tablet {
      max-height: 600px;
      bottom: -10%;
    }
    @include desktop {
      bottom: -30%;
      max-height: 800px;
    }
    @include widescreen {
      max-height: 1100px;
    }
  }

  &.lower {
    bottom: 0;
    transform: translateX(-10%);
    max-height: 400px;

    @include tablet {
      max-height: 600px;
      bottom: -10%;
    }
    @include desktop {
      max-height: 800px;
      bottom: -30%;
    }
    @include widescreen {
      max-height: 1100px;
    }
  }
}
</style>
