ls <template>
  <section class="answer-container">
    <PauseVolume
      class="volume-svg pause"
      @click="playAudio"
      :class="checkVolumeTheme"
      v-if="this.playing"
    />
    <VolumeButton
      class="volume-svg"
      @click="playAudio"
      :class="checkVolumeTheme"
      v-else
    />
    <audio ref="audio" class="hidden-audio" @ended="audioEnded">
      <source :src="this.localAudio" type="audio/mp3" />
    </audio>

    <div class="graphic-text-container">
      <CheckMark
        v-if="
          correctAnswer && (getTheme === 'water' || getTheme === 'water dark')
        "
        class="answer-graphic checkmark"
      />
      <CheckMarkSpace
        v-else-if="
          correctAnswer && (getTheme === 'space' || getTheme === 'space dark')
        "
        class="answer-graphic checkmark"
      />
      <CheckMarkCity
        v-else-if="
          correctAnswer && (getTheme === 'city' || getTheme === 'city dark')
        "
        class="answer-graphic checkmark"
      />
      <WrongAnswer
        v-else-if="
          !correctAnswer && (getTheme === 'water' || getTheme === 'water dark')
        "
        class="answer-graphic wrong-answer"
      />
      <WrongAnswerSpace
        v-else-if="
          !correctAnswer && (getTheme === 'space' || getTheme === 'space dark')
        "
        class="answer-graphic wrong-answer"
      />
      <WrongAnswerCity
        v-else-if="
          !correctAnswer && (getTheme === 'city' || getTheme === 'city dark')
        "
        class="answer-graphic wrong-answer"
      />
      <h1 class="answer-text" :class="checkTheme">
        {{ renderResponse }}
      </h1>
    </div>

    <button @click="nextQuestion" class="button answer-button single">
      <span v-if="!showLast">
        Neste spørsmål
      </span>
      <span v-if="showLast">
        Se resultat
      </span>
    </button>
  </section>
</template>

<script>
import CheckMark from "@/svgs/ctg1/checkmark.svg";
import CheckMarkSpace from "@/svgs/ctg2/checkmark.svg";
import CheckMarkCity from "@/svgs/ctg3/checkmark.svg";
import WrongAnswer from "@/svgs/ctg1/wrong.svg";
import WrongAnswerSpace from "@/svgs/ctg2/wrong.svg";
import WrongAnswerCity from "@/svgs/ctg3/wrong.svg";
import VolumeButton from "@/svgs/volume.svg";
import PauseVolume from "@/svgs/pause.svg";

import { gsap } from "gsap";
export default {
  components: {
    CheckMark,
    CheckMarkSpace,
    CheckMarkCity,
    WrongAnswer,
    WrongAnswerSpace,
    WrongAnswerCity,
    VolumeButton,
    PauseVolume
  },
  props: {
    question: {
      required: true,
      type: Object
    },
    answerText: {
      required: true,
      type: String
    },
    correctAnswer: {
      required: true,
      type: Boolean
    },
    audioFile: {
      required: true
    }
  },
  data() {
    return {
      localAudio: this.audioFile,
      check: true,
      playing: false
    };
  },
  computed: {
    getTheme() {
      return this.$store.getters.currentTheme;
    },
    checkTheme() {
      let theme = this.getTheme;

      return theme.includes("dark") ? "dark" : "light";
    },
    checkVolumeTheme() {
      let theme = this.getTheme;
      if (theme.includes("water")) {
        if (theme.includes("dark")) {
          return "water dark";
        }
        return "water light";
      } else if (theme.includes("space")) {
        if (theme.includes("dark")) {
          return "space dark";
        }
        return "space light";
      } else if (theme.includes("city")) {
        if (theme.includes("dark")) {
          return "city dark";
        }
        return "city light";
      }
      return "";
    },
    showLast() {
      return this.$store.getters.showLast;
    },
    renderResponse() {
      let newText = this.answerText;
      let name = this.getName;
      return newText.replace("[NAVN]", name);
    },
    getName() {
      return this.$store.getters.name;
    }
  },
  methods: {
    audioEnded() {
      this.playing = false;
    },
    playAudio() {
      let audio = this.$refs.audio;
      audio.volume = 0.2;
      if (audio.paused) {
        this.playing = true;
        audio.play();
      } else {
        this.playing = false;
        audio.pause();
      }
    },
    nextQuestion() {
      this.playing = false;
      let audio = this.$refs.audio;
      audio.pause();
      let tl = gsap.timeline({
        onComplete: function() {
          this.$store.dispatch("SET_QUESTION_STEP_INCREMENT");
          if (this.question.lastOfCategory) {
            this.$store.dispatch("SET_SHOW_CTG_START");
            this.$store.dispatch("SET_CURRENT_THEME", this.question.nextTheme);
          } else {
            this.$store.dispatch("SET_SHOW_QUESTION");
          }
        },
        callbackScope: this
      });

      tl.to("#mask", 1, {
        height: "100vh"
      });
    },
    moveRandom(element, check) {
      if (check) {
        gsap.to(element, gsap.utils.random(3, 6), {
          xPercent: gsap.utils.random(-5, 5),
          yPercent: gsap.utils.random(-5, 5),
          ease: "linear",
          onComplete: this.moveRandom,
          onCompleteParams: [element, this.check]
        });
      }
    },
    animate() {
      let tl = gsap.timeline();
      gsap.set(".answer-button, .volume-svg", {
        pointerEvents: "none"
      });
      gsap.set(".answer-graphic", {
        scale: 0
      });

      let bottomGraphic = document.querySelector(".bottom-graphic");
      if (bottomGraphic !== null) {
        tl.to(".bottom-graphic", 0.2, {
          opacity: 0
        });
      }

      tl.to(".answer-graphic", 0.5, {
        opacity: 1,
        scale: 1,
        ease: "bounce"
      });
      tl.to(
        ".front",
        0.5,
        {
          opacity: 1
        },
        "-=0.1"
      );

      tl.fromTo(
        ".answer-text",
        0.5,
        {
          opacity: 0,
          scale: 0.8
        },
        {
          opacity: 1,
          scale: 1
        },
        "-=0.3"
      );

      tl.fromTo(
        ".answer-button, .volume-svg",
        1,
        {
          opacity: 0
        },
        {
          opacity: 1,
          pointerEvents: "all"
        }
      );
      let responseGraphicFront = document.querySelector(".front");
      let responseGraphicBack = document.querySelector(".background");
      this.moveRandom(responseGraphicFront, true);
      this.moveRandom(responseGraphicBack, true);
    }
  },
  mounted() {
    this.animate();
  },
  beforeDestroy() {
    this.check = false;
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/media-queries";

.hidden-audio {
  position: absolute;
  left: -9999px;
}

.answer-container {
  height: 100%;
  display: grid;
  place-items: center;
  grid-template-rows: 1fr 1fr 1fr 1fr 27px;

  @include tablet {
    grid-template-rows: 1fr 1fr 1fr 1fr 60px;
  }

  .graphic-text-container {
    grid-row: 1 / 4;
    display: grid;
    place-items: center;
    text-align: center;
    @include tablet {
      h1 {
        width: 100%;
      }
    }
    .answer-text {
      margin-top: 20px;
      @include desktop {
        max-width: 700px;
        margin-left: 80px;
        margin-right: 80px;
      }

      @include font-size-response();
      @include tablet {
        margin-top: 30px;
      }

      z-index: 1;
      &.dark {
        color: #fff;
      }
    }
  }

  .answer-graphic {
    height: 20vh;
    opacity: 0;
    z-index: 0;
    overflow: initial;
    .front {
      opacity: 0;
    }
    @include removeGraphic {
      display: none;
    }
    @include tablet {
      //margin-top: 12vh;
    }
    @include desktop {
      //margin-top: 10vh;
    }
  }

  .answer-button {
    grid-row: 4 / 5;
    align-self: end;
    @include tablet {
      @include height1024 {
        margin-bottom: 80px;
      }
    }
  }
}
</style>
