<template>
  <header>
    <TelenorLogo class="telenor-logo" alt="Telenor Logo" />

    <div
      v-if="getRemaining !== 0 && !showEnding && !showStartScreen"
      class="steps-left"
    >
      <HeaderLeft class="steps" :remaining="getRemaining" />
      <LeftText class="left" :class="checkTheme" />
    </div>
    <a
      href="https://korspaahalsen.rodekors.no/"
      target="_blank"
      rel="noreferrer noopener"
      v-if="showStartScreen || showEnding"
    >
      <CorpLogoM class="corp-logo-mobile" alt="Telenor i Samarbeid med" />
      <CorpLogo class="corp-logo" alt="Telenor i Samarbeid med" />
      <RkorsLogo class="rk-logo" alt="Røde kors, kors på halsen" />
    </a>
  </header>
</template>

<script>
import CorpLogo from "@/assets/i-samarbeid-med-desktop.svg";
import CorpLogoM from "@/assets/i-samarbeid-med-mobile.svg";
import RkorsLogo from "@/assets/Rkorslogo.svg";
import TelenorLogo from "@/assets/telenor_logo.svg";
import questions from "./questions.js";
import LeftText from "@/svgs/header/left-text.svg";
import HeaderLeft from "./HeaderLeft.vue";

export default {
  components: {
    RkorsLogo,
	CorpLogo,
	CorpLogoM,
    TelenorLogo,
    LeftText,
    HeaderLeft
  },
  computed: {
    getRemaining() {
      let questionStep = this.$store.getters.questionStep + 1;
      let questionsLength = questions.length;

      return questionsLength - questionStep;
    },
    showStartScreen() {
      return this.$store.getters.showStart;
    },
    showEnding() {
      return this.$store.getters.showEnd;
    },
    checkTheme() {
      return this.$store.getters.currentTheme;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/media-queries.scss";

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    width: 50px;
  }

  & > * {
    margin: 0 20px;
    @include tablet {
      margin: 0 40px;
    }
  }

  .telenor-logo {
    height: 50px;
  @include mobile
	{
      margin-top: 20px;
	  margin-right:0px;
	}
	
	
	
    @include tablet {
      height: 55px;
      margin-top: 14px;
    }
    @include desktop {
      height: 75px;
      margin-top: 30px;
    }
  }
  
  .corp-logo-mobile
  {
  @include mobile
	{
	  width:190px;
      margin-top: 35px;
      margin-right: 10px;
	}
	
    @include tablet {
		display: none;
    }
    @include desktop {
		display: none;
    } 
}


  
  
  
  .corp-logo {
  
  @include mobile
	{
		display: none;
	}
	
    @include tablet {
      margin-top: 35px;
      width: 280px;
      margin-right: 20px;
    }
    @include desktop {
      width: 280px;
      margin-top: 40px;
      margin-right: 20px;
    }
  }
  
  
  .rk-logo {
    width: 60px;
	
    @include tablet {
      margin-top: 35px;
      width: 90px;
    }
    @include desktop {
      width: 90px;
      margin-top: 40px;
      //margin-right: 40px;
    }
  }
}
.steps-left {
  text-align: center;
  display: flex;
  flex-direction: column;
  @include tablet {
    margin-top: 20px;
  }
  @include desktop {
    margin-top: 30px;
  }

  .steps {
    height: 18px;
    @include tablet {
      height: 24px;
    }

    @include desktop {
      height: 30px;
    }
  }

  .left {
    margin-top: 5px;
    height: 18px;
    @include tablet {
      height: 21px;
    }
    @include desktop {
      height: 22px;
    }
    &.dark {
      path {
        fill: #fff;
      }
    }
  }
}
</style>
