<template>
  <main class="main-container">
    <StartScreen v-if="showStart"> </StartScreen>

    <CategoryStart v-if="showCtgStart" />
    <Question
      v-else-if="
        checkStep(questions[currentStep].id) &&
          showQuestion &&
          !showStart &&
          !showEnd &&
          !showCtgStart
      "
      @click="checkClick"
      :question="questions[currentStep]"
    >
    </Question>
    <Response
      v-else-if="
        checkStep(questions[currentStep].id) &&
          !showQuestion &&
          !showStart &&
          !showEnd &&
          !showCtgStart
      "
      :question="questions[currentStep]"
      :answerText="answerText"
      :correctAnswer="correctAnswer"
      :audioFile="audio"
    >
    </Response>

    <EndingScreen v-else-if="showEnd"> </EndingScreen>
  </main>
</template>

<script>
import Question from "./Question.vue";
import Response from "./Response.vue";
import StartScreen from "./StartScreen.vue";
import CategoryStart from "./CategoryStart.vue";
import EndingScreen from "./EndingScreen.vue";
import questions from "./questions.js";

export default {
  components: {
    StartScreen,
    CategoryStart,
    Question,
    Response,
    EndingScreen
  },
  data() {
    return {
      questions: questions,
      correctAnswer: null,
      answerText: null,
      audio: null
    };
  },
  computed: {
    showStart() {
      return this.$store.getters.showStart;
    },
    currentStep() {
      return this.$store.getters.questionStep;
    },
    showQuestion() {
      return this.$store.getters.showQuestion;
    },
    showEnd() {
      return this.$store.getters.showEnd;
    },
    showCtgStart() {
      return this.$store.getters.showCtgStart;
    }
  },
  methods: {
    checkClick(event, correctAnswer) {
      if (correctAnswer.includes(event)) {
        this.$store.dispatch("SET_TOTAL_CORRECT");
        this.correctAnswer = true;
      } else {
        this.correctAnswer = false;
      }
      let currentQuestion = this.questions[this.currentStep];
      if (event === "one") {
        this.answerText = currentQuestion.answerOne;
        this.audio = currentQuestion.audio_response1;
      } else if (event === "two") {
        this.answerText = currentQuestion.answerTwo;
        this.audio = currentQuestion.audio_response2;
      } else if (event === "three") {
        this.answerText = currentQuestion.answerThree;
        this.audio = currentQuestion.audio_response3;
      }
      this.nextTheme = this.questions[this.currentStep + 1].theme;
      this.$store.dispatch("SET_NEXT_THEME", this.nextTheme);
      this.$store.dispatch("SET_SHOW_QUESTION");
    },
    checkStep(index) {
      if (index === this.currentStep) {
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
