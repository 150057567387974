<template>
  <div>
    <svg width="100%" height="100%" class="path-container">
      <path
        fill="none"
        id="start-screen-path1"
        d="M5,133 C5,164 284,40 331,167 439.598,259.5 1.327,570.949 125.18,866.344 555.816,1156.896 924.097,576 952,576"
      />
    </svg>
    <svg width="100%" height="100%" class="path-container">
      <path
        fill="none"
        id="start-screen-path2"
        d="M1132,744 C1132,775 1223.323,569.943 863.179,409.342 551.701,270.44 587.097,161 615,161"
      />
    </svg>
    <svg width="100%" height="100%" class="path-container">
      <path
        fill="none"
        id="start-screen-path3"
        d="M0,0C0,31,583.161,22.747,673.999,368,814.355,901.461,1868,221,1899,221"
      />
    </svg>
    <svg class="path-container four">
      <path
        fill="none"
        id="start-screen-path4"
        d="M-528,1212 C-528,1243 -353.67,1159.744 -186,813 68.466,286.76 -646,-149 -615,-149"
      />
    </svg>
    <WhiteSnake class="snake s-one" />
    <WhiteSnake class="snake s-two" />
    <Rainbow class="rainbow r-one" />
    <Rainbow class="rainbow r-two" />
  </div>
</template>

<script>
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import WhiteSnake from "@/svgs/white_snake.svg";
import Rainbow from "@/svgs/ctg3/small-rainbow.svg";

export default {
  components: {
    Rainbow,
    WhiteSnake
  },
  methods: {
    animate() {
      gsap.fromTo(
        ".rainbow",
        10,
        {
          rotation: -15
        },
        {
          rotation: 15,
          yPercent: 100,
          repeat: -1,
          ease: "linear",
          yoyo: true
        }
      );
      gsap.to(".s-one", 30, {
        motionPath: {
          path: "#start-screen-path1",
          align: "#start-screen-path1",
          alignOrigin: [0.5, 0.5],
          autoRotate: 90
        },
        opacity: 0,
        ease: "linear",
        repeat: -1
      });
      gsap.to(".s-two", 30, {
        motionPath: {
          path: "#start-screen-path2",
          align: "#start-screen-path2",
          alignOrigin: [0.5, 0.5],
          autoRotate: 90
        },
        opacity: 0,
        ease: "linear",
        repeat: -1
      });
      gsap.to(".r-one", 40, {
        motionPath: {
          path: "#start-screen-path3",
          align: "#start-screen-path3",
          alignOrigin: [0.5, 0.5],
          autoRotate: 90
        },
        opacity: 0,
        ease: "linear",
        repeat: -1
      });
      gsap.to(".r-two", 40, {
        motionPath: {
          path: "#start-screen-path4",
          align: "#start-screen-path4",
          alignOrigin: [0.5, 0.5],
          autoRotate: 90
        },
        opacity: 0,
        ease: "linear",
        repeat: -1
      });
    }
  },
  mounted() {
    gsap.registerPlugin(MotionPathPlugin);
    this.animate();
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media-queries";
.path-container {
  position: absolute;
  &.four {
    left: 90%;
  }
}
.rainbow {
  position: absolute;
  height: 30px;
  opacity: 0.5;
  &.r-one {
    top: 50%;
    left: 2%;
  }
  &.r-two {
    right: 5%;
  }
}
.snake {
  position: absolute;
  height: 80px;
  @include mobile {
    height: 40px;
  }
}
</style>
