<template>
  <div>
    <ConstellationOne class="constellation c-one" />
    <ConstellationTwo class="constellation c-two" />
    <ConstellationThree class="constellation c-three" />
    <RedPlanet class="red-planet" />
  </div>
</template>

<script>
import ConstellationOne from "../../svgs/ctg2/constellation-one.svg";
import ConstellationTwo from "../../svgs/ctg2/constellation-two.svg";
import ConstellationThree from "../../svgs/ctg2/constellation-three.svg";
import RedPlanet from "../../svgs/ctg2/red-planet.svg";
import { gsap } from "gsap";

export default {
  components: {
    ConstellationOne,
    ConstellationTwo,
    ConstellationThree,
    RedPlanet
  },
  data() {
    return {
      check: true
    };
  },
  methods: {
    moveRandom(element, check) {
      if (check) {
        gsap.to(element, gsap.utils.random(10, 15), {
          yPercent: gsap.utils.random(-100, 100),
          ease: "linear",
          onComplete: this.moveRandom,
          onCompleteParams: [element, this.check]
        });
      }
    },
    animateStart() {
      let vw = window.innerWidth;

      gsap.set(".c-one", {
        x: 0
      });
      gsap.set(".c-two", {
        x: vw
      });
      gsap.set(".c-three", {
        x: vw
      });
      let allConstellations = document.querySelectorAll(".constellation");
      allConstellations.forEach(constellation => {
        this.moveRandom(constellation, true);
      });

      allConstellations.forEach(constellation => {
        let bounding = constellation.getBoundingClientRect();
        let cTl = gsap.timeline({ repeat: -1 });
        gsap.to(constellation, 10, {
          scale: 1.5,
          yoyo: true,
          repeat: -1,
          ease: "linear"
        });
        if (bounding.left > 0) {
          cTl.to(constellation, gsap.utils.random(40, 70), {
            x: -150,
            rotation: 360,
            ease: "linear"
          });
        } else {
          cTl.to(constellation, gsap.utils.random(40, 70), {
            x: vw,
            rotation: 360,
            ease: "linear"
          });
        }
      });

      let planet = document.querySelector(".red-planet");
      this.moveRandom(planet);

      gsap.set(".red-planet", {
        x: vw
      });
      gsap.to(".red-planet", 120, {
        x: -100,
        repeat: -1,
        ease: "linear"
      });
    }
  },
  mounted() {
    this.animateStart();
  },
  beforeDestroy() {
    this.check = false;
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/media-queries";

.c-one {
  position: absolute;
  height: 60px;
  overflow: initial;
  top: 50%;
}
.c-two {
  position: absolute;
  height: 80px;
  top: 70%;
  transform: rotate(90deg);
}
.c-three {
  @include mobile {
    display: none;
  }
  position: absolute;
  top: 25%;
  height: 80px;
}
.red-planet {
  @include mobile {
    height: 60px;
  }
  position: absolute;
  height: 100px;
}

.cs-one-end-container {
  display: none;
}
</style>
