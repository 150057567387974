var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main-container"},[(_vm.showStart)?_c('StartScreen'):_vm._e(),(_vm.showCtgStart)?_c('CategoryStart'):(
      _vm.checkStep(_vm.questions[_vm.currentStep].id) &&
        _vm.showQuestion &&
        !_vm.showStart &&
        !_vm.showEnd &&
        !_vm.showCtgStart
    )?_c('Question',{attrs:{"question":_vm.questions[_vm.currentStep]},on:{"click":_vm.checkClick}}):(
      _vm.checkStep(_vm.questions[_vm.currentStep].id) &&
        !_vm.showQuestion &&
        !_vm.showStart &&
        !_vm.showEnd &&
        !_vm.showCtgStart
    )?_c('Response',{attrs:{"question":_vm.questions[_vm.currentStep],"answerText":_vm.answerText,"correctAnswer":_vm.correctAnswer,"audioFile":_vm.audio}}):(_vm.showEnd)?_c('EndingScreen'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }