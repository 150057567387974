var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"answer-container"},[(this.playing)?_c('PauseVolume',{staticClass:"volume-svg pause",class:_vm.checkVolumeTheme,on:{"click":_vm.playAudio}}):_c('VolumeButton',{staticClass:"volume-svg",class:_vm.checkVolumeTheme,on:{"click":_vm.playAudio}}),_c('audio',{ref:"audio",staticClass:"hidden-audio",on:{"ended":_vm.audioEnded}},[_c('source',{attrs:{"src":this.localAudio,"type":"audio/mp3"}})]),_c('div',{staticClass:"graphic-text-container"},[(
        _vm.correctAnswer && (_vm.getTheme === 'water' || _vm.getTheme === 'water dark')
      )?_c('CheckMark',{staticClass:"answer-graphic checkmark"}):(
        _vm.correctAnswer && (_vm.getTheme === 'space' || _vm.getTheme === 'space dark')
      )?_c('CheckMarkSpace',{staticClass:"answer-graphic checkmark"}):(
        _vm.correctAnswer && (_vm.getTheme === 'city' || _vm.getTheme === 'city dark')
      )?_c('CheckMarkCity',{staticClass:"answer-graphic checkmark"}):(
        !_vm.correctAnswer && (_vm.getTheme === 'water' || _vm.getTheme === 'water dark')
      )?_c('WrongAnswer',{staticClass:"answer-graphic wrong-answer"}):(
        !_vm.correctAnswer && (_vm.getTheme === 'space' || _vm.getTheme === 'space dark')
      )?_c('WrongAnswerSpace',{staticClass:"answer-graphic wrong-answer"}):(
        !_vm.correctAnswer && (_vm.getTheme === 'city' || _vm.getTheme === 'city dark')
      )?_c('WrongAnswerCity',{staticClass:"answer-graphic wrong-answer"}):_vm._e(),_c('h1',{staticClass:"answer-text",class:_vm.checkTheme},[_vm._v(" "+_vm._s(_vm.renderResponse)+" ")])],1),_c('button',{staticClass:"button answer-button single",on:{"click":_vm.nextQuestion}},[(!_vm.showLast)?_c('span',[_vm._v(" Neste spørsmål ")]):_vm._e(),(_vm.showLast)?_c('span',[_vm._v(" Se resultat ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }