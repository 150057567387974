<template>
  <div>
    <svg class="path-container">
      <path
        fill="none"
        id="path1"
        d="M449,-400 C449,-369 1.823,-283.345 24,75 50.298,499.949 257.44,497.951 585.75862,414.94688 1036.169,301.075 2280.949,916 2298,916"
      />
    </svg>
    <svg width="100%" height="100%" class="path-container">
      <path
        fill="none"
        id="path2"
        d="M826,1209 C826,1240 1223.323,569.943 863.179,409.342 551.701,270.44 706.097,-7 734,-7"
      />
    </svg>
    <svg width="100%" height="100%" class="path-container">
      <path
        fill="none"
        id="path3"
        d="M603,367 C603,398 545.316,-340.305 23,72 -650.841,603.915 -663,-968 -632,-968"
      />
    </svg>
    <svg width="100%" height="100%" class="path-container">
      <path
        fill="none"
        id="path4"
        d="M0,0C0,31,583.161,22.747,673.999,368,814.355,901.461,1868,221,1899,221"
      />
    </svg>
    <svg class="path-container four">
      <path
        fill="none"
        id="path5"
        d="M-528,1212 C-528,1243 -353.67,1159.744 -186,813 68.466,286.76 -646,-149 -615,-149"
      />
    </svg>
    <Rainbow class="rainbow rb-one" />
    <Rainbow class="rainbow rb-two" />
    <Rainbow class="rainbow rb-three" />
    <Triangle class="triangle t-one" />
    <Triangle class="triangle t-two" />
  </div>
</template>

<script>
import Rainbow from "@/svgs/ctg3/small-rainbow.svg";
import Triangle from "@/svgs/ctg3/blue-triangle.svg";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
export default {
  components: {
    Rainbow,
    Triangle
  },
  methods: {
    animate() {
      let triangles = document.querySelectorAll(".triangle");
      triangles.forEach(triangle => {
        gsap.to(triangle, gsap.utils.random(7, 12), {
          rotation: 360,
          ease: "linear",
          repeat: -1,
          scale: 1.8,
          yoyo: true
        });
      });
      let strokeTl = gsap.timeline({ yoyo: true, repeat: -1, ease: "linear" });
      gsap.to(".rb-one", 40, {
        motionPath: {
          path: "#path1",
          align: "#path1",
          alignOrigin: [0.5, 0.5],
          autoRotate: 90
        },
        opacity: 0,
        ease: "linear",
        repeat: -1
      });
      gsap.to(".rb-two", 40, {
        motionPath: {
          path: "#path2",
          align: "#path2",
          alignOrigin: [0.5, 0.5],
          autoRotate: 90
        },
        ease: "linear",
        repeat: -1
      });
      gsap.to(".rb-three", 40, {
        motionPath: {
          path: "#path3",
          align: "#path3",
          alignOrigin: [0.5, 0.5],
          autoRotate: 90
        },
        opacity: 0,
        ease: "linear",
        repeat: -1
      });
      gsap.to(".t-one", 40, {
        motionPath: {
          path: "#path4",
          align: "#path4"
        },
        opacity: 0,
        ease: "linear",
        repeat: -1
      });
      gsap.to(".t-two", 40, {
        motionPath: {
          path: "#path5",
          align: "#path5"
        },
        opacity: 0,
        ease: "linear",
        repeat: -1
      });

      strokeTl.to(".stroke-one", 2, {
        drawSVG: "0%"
      });
      strokeTl.to(".stroke-two", 2, {
        drawSVG: "0%"
      });
      strokeTl.to(".stroke-three", 2, {
        drawSVG: "0%"
      });
    }
  },
  mounted() {
    gsap.registerPlugin(MotionPathPlugin);
    this.animate();
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media-queries.scss";
.path-container {
  position: absolute;
  &.four {
    left: 90%;
  }
}

.rainbow * {
  stroke: #ebc387;
}

.rainbow {
  height: 25px;
  &.rb-three {
    @include mobile {
      display: none;
    }
  }
}

.triangle {
  position: absolute;
  height: 20px;
  &.t-two {
    @include mobile {
      display: none;
    }
  }
}
</style>
